import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FilterOption from "../common/FilterOption";
import LoadingSpinner from "../loadingspinner/LoadingSpinner";
import LoadingDataError from "../common/LoadingDataError";
import QueryJobFairs from "./QueryJobFairs";
import StringUtils from "../../utils/StringUtils";
import TypeUtils from "../../utils/TypeUtils";

class JobFairFilter extends React.Component {

  state = {
    newValue: "",
  };

  showLoading = () => {
    return <LoadingSpinner/>;
  };

  showError = () => {
    return <LoadingDataError/>;
  };


  onValueChecked = value => {
    // Add value
    const selectedValuesSet = new Set(this.props.selectedValues);
    selectedValuesSet.add(value);
    this.props.updateSelectedValues([...selectedValuesSet]);
  };

  onValueUnchecked = value => {
    // Remove value from selected set
    const selectedValuesSet = new Set(this.props.selectedValues);
    selectedValuesSet.delete(value);
    this.props.updateSelectedValues([...selectedValuesSet]);
  };

  inputOnChange = event => {
    this.setState({
      newValue: event.target.value
    });
  };

  render() {
    const selectedValues = TypeUtils.ensureArray(this.props.selectedValues);

    return (
      <Form className="JobFairFilter filter">
        <QueryJobFairs
          onLoading={this.showLoading}
          onError={this.showError}
          onLoaded={jobFairs => {

            // Display filter only if there is more than one job fairs in the system
            return (
              <div className="JobFairFilter">
                {TypeUtils.ensureArray(jobFairs).length > 1 &&
                <Row>
                  <Col>
                    {jobFairs.map(jobFair => {
                      const isSelected = selectedValues.includes(jobFair._id);

                      return (
                        <FilterOption
                          key={jobFair._id}
                          checked={isSelected}
                          onClick={() => isSelected ? this.onValueUnchecked(jobFair._id) : this.onValueChecked(jobFair._id)}
                        >
                          {jobFair.title} {!StringUtils.isNullOrEmpty(jobFair.city) && <span>({jobFair.city})</span>}
                        </FilterOption>
                      );
                    })}
                  </Col>
                </Row>
                }
              </div>
            );
          }}
        />
      </Form>
    );
  }
}

export default JobFairFilter;
