import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import JobManagementRoutes from "./JobManagementRoutes";
import {Link, withRouter} from "react-router-dom";
import JobOpeningsListItem from "./JobOpeningsListItem";
import Card from "react-bootstrap/Card";
import "./JobOpeningsList.scss";
import AddIcon from "@material-ui/icons/Add";
import withRoundButton from "../materialicon/withRoundButton";
import TypeUtils from "../../utils/TypeUtils";
import {withTranslation} from "react-i18next";
import withAuthUser from "../session/withAuthUser";

class JobOpeningsList extends React.Component {

  render() {
    const t = this.props.t;
    const openings = TypeUtils.ensureArray(this.props.openings);

    // Title is different whether we are viewing our own account
    const title = (JobManagementRoutes.getCompanyIdFromRoute(this.props.match) === this.props.authUser.profile._id) ?
      t("jobmanagement:opening_list_title") : this.props.companyName;

    return (
      <div className="JobOpeningsList">
        <Card>
          <Card.Header>
            <Row>
              <Col>
                {title}
              </Col>
              <Col xs="auto">
                <Link to={JobManagementRoutes.getOpeningAddRoute(this.props.match)}>
                  <WithRoundButtonAddIcon className="add-button" title={t("jobmanagement:opening_list_add_icon")}/>
                </Link>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            {openings.map((opening) => (
              <Row key={opening._id}>
                <Col>
                  <JobOpeningsListItem
                    jobItem={opening}
                    selected={this.props.selectedOpening === opening._id}
                    selectOpening={() => this.props.selectOpening(opening._id)}
                  />
                </Col>
              </Row>
            ))}
            {openings.length === 0 && (
              <Row>
                <Col>
                  <span>{t("jobmanagement:opening_list_none")}</span>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const WithRoundButtonAddIcon = withRoundButton(AddIcon);
export default withTranslation()(withRouter(withAuthUser(JobOpeningsList)));
