import React from "react";
import { ApolloConsumer } from "react-apollo";
import withButton from "../materialicon/withButton";
import ResumeIcon from "@material-ui/icons/Attachment";
import {withTranslation} from "react-i18next";
import ErrorModal from "../common/ErrorModal";
import gql from "graphql-tag";
import StringUtils from "../../utils/StringUtils";

class DownloadResumeButton extends React.Component {

  static GET_RESUME_DOWNLOAD_URL = gql`
    query bslJobFairResumeDownloadUrl($_id: String!) {
      bslJobFairResumeDownloadUrl(_id: $_id)
    }
  `;

  state = {
    error: null,
    loading: false,
  };


  onClickResume = (event, client) => {
    event.preventDefault();

    this.setState({
      error: null,
      loading: true
    }, () => this.getDownloadUrl(client)
    );
  };

  getDownloadUrl = (client) => {
    return client.query({
      query: DownloadResumeButton.GET_RESUME_DOWNLOAD_URL,
      variables: {
        _id: this.props.resumeId
      }
    }).then(result => {
      this.setState({loading: false});
      const downloadUrl = result.data["bslJobFairResumeDownloadUrl"];
      if (!StringUtils.isNullOrEmpty(downloadUrl)) {
        // This will trigger the file download (window.open(downloadUrl) produces a glitch)
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.click();
      }
    }).catch(err => {
      this.setState({
        loading: false,
        error: this.props.t("jobmanagement:candidate_download_resume_error") + err.message
      });
    })
  };

  clearError = () => {
    this.setState({ error: null });
  };

  render() {
    const t = this.props.t;

    return (
      <React.Fragment>
        {/* The modal is visible only when operation returned an error */}
        <ErrorModal
          show={this.state.error !== null}
          handleClose={this.clearError}
        >
          <p>{this.state.error}</p>
        </ErrorModal>
        <ApolloConsumer>
          {client =>
            <WithButtonResumeIcon
              title={t("jobmanagement:candidate_view_resume_icon_title")}
              onClick={(event) => this.onClickResume(event, client)}
              disabled={this.props.disabled || this.state.loading || StringUtils.isNullOrEmpty(this.props.resumeId)}
            />
          }
        </ApolloConsumer>
      </React.Fragment>
    );
  }
}

const WithButtonResumeIcon = withButton(ResumeIcon);

export default withTranslation()(DownloadResumeButton);
