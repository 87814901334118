import React from "react";
import TypeUtils from "../../utils/TypeUtils";

/**
 * Higher order component that takes a component as argument and returns another component.
 * This HOC is intended to wrap a component that is an item in a list that is wrapped with withListHandlers.
 * It provides the wrapped component with handlers to modify itself and update the containing list.
 * We assume the wrapped component will call this HOC's onChangeInput handler when input is typed.
 * A copy of the item list is made whenever one of its attributes is modified because we assume the item is a React component
 * property that must not be modified directly.
 * @param WrappedComponent
 * @returns {}
 */
function withListItemHandlers(WrappedComponent) {

  /**
   * Enhanced component to return
   */
  return class extends React.Component {

    /**
     * Make a copy of the item and set one of its properties to a given value
     * @param key Property to modify
     * @param value New value
     */
    setItemField = (key, value) => {
      // Copy object because we can't modify props directly
      this.props.updateItem(TypeUtils.shallowCopyObjectSetProp(this.props.item, key, value),
      );
    };

    /**
     * Use the information from an event fired when an input box is changed to modify a property of the item
     * The name of the property is what follows the current item "name prefix" in the input box HTML name.
     * @param event
     */
    onChangeInput = event => {
      event.preventDefault();
      const field = event.target.name.substring(this.props.namePrefix.length);
      this.setItemField(field, event.target.value);
    };

    render() {
      // Render the wrapped component and augment it with additional list handlers
      return <WrappedComponent
        {...this.props}
        onChangeInput={this.onChangeInput}
        setItemField={this.setItemField}
      />;
    }
  };
}

export default withListItemHandlers;
