import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";

class QueryCompany extends React.Component {
  static GET_COMPANY = gql`
    query CompanyByEmail($email: String!) {
      companyByEmail(email: $email) {
        _id
        name
        roles
      }
    }
  `;

  render() {
    return (
      <Query
        query={QueryCompany.GET_COMPANY}
        variables={{ email: this.props.email }}
      >
        {result => {
          if (result.loading) return this.props.onLoading();
          if (result.error) return this.props.onError();
          return this.props.onLoaded(result.data.companyByEmail, result.refetch);
        }}
      </Query>
    );
  }
}

export default QueryCompany;
