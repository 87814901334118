import React from "react";
import withFirebase from "../firebase/withFirebase";
import {Redirect} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import Page from "../page/Page";
import withAuthUser from "../session/withAuthUser";
import {withTranslation} from "react-i18next";

class SignOutPage extends React.Component {

  componentDidMount() {
    this.props.firebase.doSignOut();
  }

  render() {
    const t = this.props.t;

    // If user is not signed in, redirect to Sign In page;
    // do not use <PrivatePage> component here because we don't want to redirect to sign in with this page
    // as a referrer
    if (!this.props.authUser.isAuthenticated)
      return <Redirect to={ROUTES.HOME}/>;

    return (
      <Page>
        <div className="SignOutPage">
          {t("session:sign_out_msg")}
        </div>
      </Page>
    );
  }
}

export default withTranslation()(withFirebase(withAuthUser(SignOutPage)));
