import React, { Component } from 'react';
import withFirebase from "../firebase/withFirebase";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import FormText from "react-bootstrap/FormText";
import SuccessModal from "../common/SuccessModal";
import LoginModal from "./LoginModal";
import {withTranslation} from "react-i18next";
import Firebase from "../firebase/Firebase";

class PasswordChangeForm extends Component {

  INITIAL_STATE = {
    passwordOne: '',
    passwordTwo: '',
    error: null,
  };

  constructor(props) {
    super(props);

    this.state = { ...this.INITIAL_STATE, showResult: false, askLogin: false };
  }

  hideResult = () => {
    this.setState({showResult: false});
  };

  hideLogin = () => {
    this.setState({askLogin: false});
  };

  onSubmit = event => {
    event.preventDefault();

    // Hide previous error, then perform change
    this.setState({error: null}, this.updatePassword);
  };

  updatePassword = () => {
    const t = this.props.t;

    this.props.firebase.doPasswordUpdate(this.state.passwordOne)
      .then(() => {
        this.setState({ ...this.INITIAL_STATE, showResult: true });
      })
      .catch(error => {
        // If user has logged too long ago, ask for current password to login again before performing pwd update
        if (error.code === "auth/requires-recent-login")
          this.setState({askLogin: true});
        else {
          const message = t(Firebase.getTranslationKey(error.code)) + " (code: " + error.code + ")";
          this.setState({ error: message });
        }
      });
  };

  onLogin = () => {
    this.hideLogin();
    this.updatePassword();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  canSubmit = () => {
    return (this.state.passwordOne !== '' && this.state.passwordOne === this.state.passwordTwo);
  };

  render() {
    const t = this.props.t;

    const { passwordOne, passwordTwo, error, showResult } = this.state;

    return (
      <React.Fragment>
        <Form onSubmit={this.onSubmit}>
          {/* The modal is visible only when form has been submitted successfully */}
          <SuccessModal show={showResult} handleClose={this.hideResult}>
            <p>{t("settings:password_change_success_msg")}</p>
          </SuccessModal>
          {/* Main form with fields for password update */}
          <FormGroup>
            <FormControl
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder={t("settings:password_change_new_password_placeholder")}
            />
          </FormGroup>
          <FormGroup>
            <FormControl
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder={t("settings:password_change_confirm_placeholder")}
            />
          </FormGroup>
          <FormGroup>
            <Button disabled={!this.canSubmit()} type="submit">
              {t("settings:password_change_reset_button")}
            </Button>
          </FormGroup>
          {error && <FormText as="div" className="form-error">{error.message}</FormText>}
        </Form>
        {/* If a login is required, ask a new form in a modal, and keep pwd update form in the background */}
        <LoginModal show={this.state.askLogin} onComplete={this.onLogin} handleClose={this.hideLogin}/>
      </React.Fragment>
    );
  }
}

export default withTranslation()(withFirebase(PasswordChangeForm));
