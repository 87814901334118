import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./ErrorModal.scss";
import {withTranslation} from "react-i18next";
import StatusIcon from "../../img/incorrect.png";
import Image from "react-bootstrap/Image";

class ErrorModal extends React.Component {

  render() {
    const t = this.props.t;

    return (
      <Modal show={this.props.show} onHide={this.props.handleClose} className="ErrorModal">
        <Modal.Body className="spaced-children">
          <div>
            <Image src={StatusIcon} className={"status-icon"}/>
          </div>
          <div>
            {this.props.children}
          </div>
          <div className={"button-row"}>
            <Button variant="primary" onClick={this.props.handleClose}>
              {t("common:close_button")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withTranslation()(ErrorModal);
