import React from "react";
import PrivatePage from "../page/PrivatePage";
import {withTranslation} from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AdminFairUpdateForm from "./AdminFairUpdateForm";

class AdminFairCreatePage extends React.Component {
  render() {
    const t = this.props.t;
    return (
      <PrivatePage>
        <h1>{t("admin:fair_create")}</h1>
        <Row>
          <Col>
            <AdminFairUpdateForm/>
          </Col>
        </Row>
      </PrivatePage>
    );
  }
}



export default withTranslation()(AdminFairCreatePage);
