import React from "react";
import PrivatePage from "../page/PrivatePage";
import {withTranslation} from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoadingSpinner from "../loadingspinner/LoadingSpinner";
import LoadingDataError from "../common/LoadingDataError";
import QueryCompanyJobTotals from "./QueryCompanyJobTotals";
import "./AdminCompanyList.scss";
import Card from "react-bootstrap/Card";
import StringUtils from "../../utils/StringUtils";
import JobManagementRoutes from "../jobmanagement/JobManagementRoutes";
import {Link} from "react-router-dom";

class AdminCompanyListPage extends React.Component {
  render() {
    const t = this.props.t;
    return (
      <PrivatePage>
        <h1>{t("admin:company_list_title")}</h1>
        <div className="spaced-children">
          <Row>
            <Col>
              <WithTranslationAdminCompanyList/>
            </Col>
          </Row>
        </div>
      </PrivatePage>
    );
  }
}

class AdminCompanyList extends React.Component {
  showLoading = () => {
    return <LoadingSpinner/>;
  };

  showError = () => {
    return <LoadingDataError/>;
  };

  render() {
    const t = this.props.t;

    return (
      <QueryCompanyJobTotals onLoading={this.showLoading} onError={this.showError} onLoaded={(companyJobTotals) =>
        <div className="AdminCompanyList spaced-children">
          {companyJobTotals.map(companyJobTotal =>
            <Card key={companyJobTotal._id} className="item-card">
              <Card.Body className="item-card-body">
                <Row>
                  <Col xs={5}>
                    {companyJobTotal.name}
                  </Col>
                  <Col xs={5}>
                    {companyJobTotal.email}
                  </Col>
                  <Col xs={2} className="total-col">
                    <Link to={JobManagementRoutes.getDefaultRoute(companyJobTotal._id)}>
                      <span><b>{StringUtils.nullToZero(companyJobTotal.jobTotal)}</b> {t("admin:company_list_jobs_col")}</span>
                    </Link>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </div>
      }/>
    );
  }
}

const WithTranslationAdminCompanyList = withTranslation()(AdminCompanyList);
export default withTranslation()(AdminCompanyListPage);
