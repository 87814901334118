import React from "react";
import Dropzone from 'react-dropzone'
import "./JobDropbox.scss";
import {withTranslation} from "react-i18next";
import ErrorModal from "../common/ErrorModal";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import {withRouter} from "react-router-dom";
import QueryCurrentJobsForCompany from "./QueryCurrentJobsForCompany";
import SuccessModal from "../common/SuccessModal";
import FileConverterSupportedFormats from "../../utils/FileConverterSupportedFormats";
import xbytes from "xbytes";
import QueryMaxUploadFileSize from "../common/QueryMaxUploadFileSize";
import LoadingSpinner from "../loadingspinner/LoadingSpinner";
import LoadingDataError from "../common/LoadingDataError";
import JobManagementRoutes from "./JobManagementRoutes";

class JobDropbox extends React.Component {

  static ADD_JOB_FROM_FILE = gql`
    mutation companyAddJobFromFile($companyId: String!, $file: Upload) {
      companyAddJobFromFile(companyId: $companyId, file: $file) {
        _id
        title
        storageId
      }
    }
  `;

  state = {
    loading: false,
    showSuccess: false,
    error: null,
    jobId: null,
  };

  hideSuccess = () => {
    this.setState({ showSuccess: false }, () => {
      this.props.history.push(JobManagementRoutes.getPreviewRoute(this.props.match, this.state.jobId));
    });
  };

  hideError = () => {
    this.setState({
      error: null,
      loading: false
    });
  };

  hideNoTitleWarning = () => {
    this.setState({ showNoTitleWarning: false }, () => {
      this.props.history.push(JobManagementRoutes.getPreviewRoute(this.props.match, this.state.jobId));
    });
  };

  saveJobOpening = (file, mutationCallback) => {
    return mutationCallback({
      variables: {
        companyId: JobManagementRoutes.getCompanyIdFromRoute(this.props.match),
        file: file,
      }
    });
  };

  // Make checks on file return a promise so that the same catch clause can be used with saveJobOpening
  fileCheckAsPromise = (files, maxUploadFileSize) => {
    const t = this.props.t;

    // Display error if more than one file is dragged
    if (files.length > 1) {
      return Promise.reject(Error(t("jobmanagement:add_job_multiple_files_error")));
    }
    // Display error if file format is not supported
    if (!FileConverterSupportedFormats.isFormatSupported(files[0].name)) {
      return Promise.reject(Error(t("jobmanagement:add_job_unsupported_format_error")));
    }
    // Display error if file size is too big
    if (files[0].size > maxUploadFileSize)
      return Promise.reject(Error(t("jobmanagement:add_job_file_size_error")));

    return Promise.resolve(null);
  };

  onDrop = (files, mutationCallback, maxUploadFileSize) => {
    const t = this.props.t;

    // If we are processing another file, do nothing
    if (this.state.loading)
      return;

    // Hide previous errors, then perform save
    this.setState({ error: null, loading: true }, () => {

      // Return nothing if, for some reason, there are no files
      if (!files || files.length === 0 || files[0] === null)
        return;

      this.fileCheckAsPromise(files, maxUploadFileSize)
        .then(() => this.saveJobOpening(files[0], mutationCallback))
        .then((result) => {
          const newJob = result.data["companyAddJobFromFile"];

          if (newJob) {
            const jobHasNoTitle = newJob.title === "";

            this.setState({
              showNoTitleWarning: jobHasNoTitle,
              showSuccess: !jobHasNoTitle,
              jobId: newJob._id,
            });
          } else {
            return Promise.reject();
          }
        })
        .catch((error) => {
          let errorMsg = t("jobmanagement:add_job_cannot_analyze");
          if (error.message) {
            errorMsg = errorMsg + " " + t("jobmanagement:server_error", {serverError: error.message});
          }
          this.setState({error: errorMsg});
        });
    });
  };

  showLoading = () => {
    return <LoadingSpinner />;
  };

  showError = () => {
    return <LoadingDataError/>;
  };

  render() {

    const t = this.props.t;
    const refetchQueries = [
      {
        query: QueryCurrentJobsForCompany.GET_JOBS_FOR_COMPANY,
        variables: {
          _id: JobManagementRoutes.getCompanyIdFromRoute(this.props.match)
        }
      }
    ];

    return (
      <QueryMaxUploadFileSize onLoading={this.showLoading} onError={this.showError} onLoaded={(maxUploadFileSize) => (
         <Mutation mutation={JobDropbox.ADD_JOB_FROM_FILE} refetchQueries={refetchQueries}>
          {(uploadCallback, {loading}) => (
            <div className={"JobDropbox dropzone " + (loading ? "disabled" : "")}>
              {/* The modal is visible only when upload has been successful */}
              <SuccessModal show={this.state.showSuccess} handleClose={this.hideSuccess}>
                <p>{t("jobmanagement:add_job_success_msg")}</p>
              </SuccessModal>
              {/* The modal is visible only when new job has no title */}
              <ErrorModal show={this.state.showNoTitleWarning} handleClose={this.hideNoTitleWarning}>
                <p>{t("jobmanagement:add_job_no_title_msg_1")}</p>
                <p>{t("jobmanagement:add_job_no_title_msg_2")}</p>
              </ErrorModal>
              {/* The modal is visible only when there are errors before or during upload */}
              <ErrorModal show={this.state.error !== null} handleClose={this.hideError}>
                {this.state.error}
              </ErrorModal>
              <Dropzone onDrop={(files) => this.onDrop(files, uploadCallback, maxUploadFileSize)}>
                {({getRootProps, getInputProps}) => (
                  <section className="container">
                    <div {...getRootProps()}>
                      {this.state.loading && <p>{t("jobmanagement:add_job_processing")}</p>}
                      {!this.state.loading &&
                        <React.Fragment>
                          <input {...getInputProps()} />
                          <p>{t("jobmanagement:add_job_dropbox_placeholder_1")}</p>
                          <p>{t("jobmanagement:add_job_dropbox_placeholder_2")}</p>
                          <p>{t("jobmanagement:add_job_dropbox_placeholder_3", {"max": xbytes(maxUploadFileSize, {space: false})})}</p>
                        </React.Fragment>
                      }
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          )}
        </Mutation>
      )}/>
    );
  }
}

export default withTranslation()(withRouter(JobDropbox));
