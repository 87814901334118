import React from "react";
import FilterOption from "../common/FilterOption";
import TypeUtils from "../../utils/TypeUtils";
import Card from "react-bootstrap/Card";
import ExpandableSection from "../common/ExpandableSection";
import "./CandidateListFilters.scss";
import {withTranslation} from "react-i18next";

class CandidateListFilters extends React.Component {

  onClickApplicationsFilter = (event) => {
    event.preventDefault();
    this.props.setFilters(TypeUtils.shallowCopyObjectSetProp(this.props.filters, "applications", !this.props.filters.applications));
  };

  onClickUploadsFilter = (event) => {
    event.preventDefault();
    this.props.setFilters(TypeUtils.shallowCopyObjectSetProp(this.props.filters, "uploads", !this.props.filters.uploads));
  };

  onClickPoolFilter = (event) => {
    event.preventDefault();
    this.props.setFilters(TypeUtils.shallowCopyObjectSetProp(this.props.filters, "pool", !this.props.filters.pool));
  };

  render() {
    const t = this.props.t;

    const fairs = ["Le Banc de l'emploi (20 fév.)", "Journée Carrière UQAR (26 fév.)",
      "Rendez-vous de l'emploi de la Mitis (11 mars)", "Job dating (14 mars)", "Blitz de l'emploi (17 mars)",
      "Événement emploi (18 mars)", "Salon de l'emploi du Témiscouata (21 mars)"];


    return (
      <Card className="CandidateListFilters">
        <Card.Body>
          <ExpandableSection header={t("jobmanagement:filters_section_title")}>
            <div className="contents">

                {fairs.map((fair, index) =>
                    <FilterOption key={index}>{fair}</FilterOption>
                )}
              {/*
              <Row>
                <Col>
                  <FilterOption checked={this.props.filters.applications} onClick={this.onClickApplicationsFilter}>
                    Applications
                  </FilterOption>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FilterOption checked={this.props.filters.uploads} onClick={this.onClickUploadsFilter}>
                    Uploads
                  </FilterOption>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FilterOption checked={this.props.filters.pool} onClick={this.onClickPoolFilter}>
                    Pool
                  </FilterOption>
                </Col>
              </Row>
              */}

            </div>
          </ExpandableSection>
        </Card.Body>
      </Card>
    );
  }
}

export default withTranslation()(CandidateListFilters);
