import React, {Component, Suspense} from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SignUpPage from "../signup/SignUpPage";
import SignInPage from "../signin/SignInPage";
import CareersPage from "../footer/CareersPage";
import TermsPage from "../footer/TermsPage";
import PrivacyPage from "../footer/PrivacyPage";
import ContactPage from "../footer/ContactPage";
import JobManagementPage from "../jobmanagement/JobManagementPage";
import * as ROUTES from "../../constants/routes";
import SignOutPage from "../signout/SignOutPage";
import PasswordChangePage from "../settings/PasswordChangePage";
import PasswordForgetPage from "../settings/PasswordForgetPage";
import AnalyticsWrapper from "./AnalyticsWrapper";
import AuthUserProvider from "../session/AuthUserProvider";
import FirebaseUserProvider from "../session/FirebaseUserProvider";
import AuthenticatedApolloProvider from "../session/AuthenticatedApolloProvider";
import FirebaseProvider from "../firebase/FirebaseProvider";
import '../../utils/i18n'; // Execute constant initialization
import FullPageSuspense from "../loadingspinner/FullPageSuspense";
import AdminFairListPage from "../admin/AdminFairListPage";
import AdminFairCreatePage from "../admin/AdminFairCreatePage";
import AdminFairUpdatePage from "../admin/AdminFairUpdatePage";
import NotFoundPage from "../notfound/NotFoundPage";
import AdminCompanyListPage from "../admin/AdminCompanyListPage";
import CandidateAllPage from "../jobmanagement/CandidateAllPage";
import ThankYouPage from "../thankyou/ThankYouPage";

class App extends Component {
  render() {

    // Initialization of i18n is lazy, calling withTranslation HOC will trigger the Suspense fallback meanwhile
    return (
      <Suspense fallback={<FullPageSuspense/>}>
        <Router>
          <Route component={ThankYouPage}/>
          {/*
          <FirebaseProvider>
            <FirebaseUserProvider>
              <AuthenticatedApolloProvider>
                <AuthUserProvider>
                  <AnalyticsWrapper>
                    <Switch>

                      <Route exact path={ROUTES.HOME} component={JobManagementPage}/>*
                      <Route exact path={ROUTES.PASSWORD_CHANGE} component={PasswordChangePage}/>
                      <Route exact path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage}/>
                      <Route exact path={ROUTES.SIGN_IN} component={SignInPage}/>
                      <Route exact path={ROUTES.SIGN_UP} component={SignUpPage}/>
                      <Route exact path={ROUTES.SIGN_OUT} component={SignOutPage}/>
                      <Route exact path={ROUTES.CAREERS} component={CareersPage}/>
                      <Route exact path={ROUTES.TERMS} component={TermsPage}/>
                      <Route exact path={ROUTES.PRIVACY} component={PrivacyPage}/>
                      <Route exact path={ROUTES.CONTACT} component={ContactPage}/>
                      <Route exact path={ROUTES.JOB_MANAGEMENT} component={JobManagementPage}/>
                      <Route exact path={ROUTES.JOB_OPENING_ADD}
                        render={(props) => <JobManagementPage {...props} addOpening/>}
                      />
                      <Route exact path={ROUTES.JOB_PREVIEW} component={JobManagementPage}/>
                      <Route exact path={ROUTES.CANDIDATE_ALL} component={CandidateAllPage}/>

                      <Route exact path={ROUTES.ADMIN_COMPANIES} component={AdminCompanyListPage}/>
                      <Route exact path={ROUTES.ADMIN_FAIR} component={AdminFairListPage}/>
                      <Route exact path={ROUTES.ADMIN_FAIR_CREATE} component={AdminFairCreatePage}/>
                      <Route exact path={ROUTES.ADMIN_FAIR_UPDATE} component={AdminFairUpdatePage}/>

                      <Route component={NotFoundPage}/>
                    </Switch>
                  </AnalyticsWrapper>
                </AuthUserProvider>
              </AuthenticatedApolloProvider>
            </FirebaseUserProvider>
          </FirebaseProvider>
    */}
        </Router>
      </Suspense>
    );
  }
}

export default App;
