import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CandidateSearchResultList from "./CandidateSearchResultList";
import CandidateSearchPane from "./CandidateSearchPane";
import TypeUtils from "../../utils/TypeUtils";

class CandidateListWithSearchPane extends React.Component {

  state = {
    searchFilters: {}
  };

  updateSearchFilters = (filters) => {
    // Update state so that candidate list updates at the same time as the filters are changed by the user
    this.setState(prevState => {
      // Make a copy of the query so we don't modify the original
      const newFilters = TypeUtils.shallowCopyObject(prevState.searchFilters);
      // Return the copy but with some filters modified
      TypeUtils.assign(newFilters, filters);

      // Return so that state is set
      return {
        searchFilters: newFilters
      };
    });
  };

  render() {
    return (
      <div className="CandidateListWithSearchPane">
        <Row className="wrap-spaced-children">
          <Col sm="4">
            <CandidateSearchPane
              searchFilters={this.state.searchFilters}
              updateSearchFilters={this.updateSearchFilters}
            />
          </Col>
          <Col sm="8" xs="12">
            <CandidateSearchResultList
              searchFilters={this.state.searchFilters}
              updateSearchFilters={this.updateSearchFilters}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default CandidateListWithSearchPane;
