import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import TypeUtils from "../../utils/TypeUtils";
import { NetworkStatus } from "apollo-client";

/**
 * Send a jobPage query to GraphQL.
 *
 * This component is expected to have one child, which is function that gets the following parameters:
 * status of the query (one of QueryJobs.STATUS values), error (if any), data (an object with two
 * properties: jobResults being an array of all job openings retrieved so far and hasNextPage, a boolean
 * telling whether there is another page that can be loaded), and a callback to call when the user
 * clicks the "Load more" button.
 */
class QueryAllResumes extends React.Component {
  static GET_CANDIDATES = gql`
    query bslJobFairResumePage($after: Int!, $batchSize: Int!, $searchQuery: BslJobFairResumeSearchQueryInput) {
      bslJobFairResumePage(after: $after, batchSize: $batchSize, searchQuery: $searchQuery) {
        nodes {
          _id
          resume {
            _id
            firstName
            lastName
            updated
            resumeFile {
              filename
            }
            technicalSkills {
              skills {
                _id
                name
              }
            }
            softSkills {
              _id
              name
            }
            email
            phone
            jobTitle
          },
          jobFair {
            title
            city
          }
        }
        after
        hasNextPage
      }
    }
  `;

  static STATUS = {
    LOADING: "LOADING",
    LOADING_MORE: "LOADING_MORE",
    LOADED: "LOADED",
    ERROR: "ERROR"
  };

  static DEFAULT_BATCH_SIZE = 25;
  static BATCH_SIZE = process.env.REACT_APP_ALL_CANDIDATE_BATCH_SIZE ? process.env.REACT_APP_ALL_CANDIDATE_BATCH_SIZE :
    QueryAllResumes.DEFAULT_BATCH_SIZE;

  render() {
    const queryVariables = {
      after: 0,
      batchSize: QueryAllResumes.BATCH_SIZE,
      searchQuery: this.props.searchQuery
    };

    return (
      <Query
        query={QueryAllResumes.GET_CANDIDATES}
        variables={queryVariables}
        notifyOnNetworkStatusChange
      >
        {result => {
          // Transform results from GraphQL to an object with two properties: nodeResults and hasNextPage
          const data =
            !result.data || !result.data.bslJobFairResumePage
              ? { nodeResults: [], hasNextPage: false }
              : {
                nodeResults: result.data.bslJobFairResumePage.nodes,
                hasNextPage: result.data.bslJobFairResumePage.hasNextPage
              };

          switch (result.networkStatus) {

            case NetworkStatus.error:
              // An error occurred while fetching data
              return this.props.children(
                QueryAllResumes.STATUS.ERROR,
                result.error,
                {},
                null
              );

            case NetworkStatus.loading:
            case NetworkStatus.setVariables:
              // Query is waiting for results either after first fetch ("loading") or after a change in search filters
              // ("setVariables"), but not after a load more action.
              return this.props.children(
                QueryAllResumes.STATUS.LOADING,
                null,
                {},
                null
              );

            case NetworkStatus.fetchMore:
              // Query is waiting for additional results after a load more action.
              return this.props.children(
                QueryAllResumes.STATUS.LOADING_MORE,
                null,
                data,
                null
              );

            case NetworkStatus.ready:
              // Query has gotten all results it was waiting for

              // Define callback to fetch the next results (the ones after "after" index) when asked
              const fetchMoreSpecs = {
                query: QueryAllResumes.GET_CANDIDATES,
                variables: TypeUtils.shallowCopyObjectSetProp(
                  queryVariables,
                  "after",
                  result.data.bslJobFairResumePage.after
                ),
                updateQuery: (previousData, { fetchMoreResult: newData }) => {
                  // Backup in case result is null
                  const newNodes = newData.bslJobFairResumePage ? newData.bslJobFairResumePage.nodes : [];
                  const newAfter = newData.bslJobFairResumePage
                    ? newData.bslJobFairResumePage.after
                    : queryVariables.after;
                  const newHasNextPage = newData.bslJobFairResumePage
                    ? newData.bslJobFairResumePage.hasNextPage
                    : false;

                  // result.data will be replaced by what we return here
                  return {
                    bslJobFairResumePage: {
                      nodes: [...previousData.bslJobFairResumePage.nodes, ...newNodes],
                      after: newAfter,
                      hasNextPage: newHasNextPage,
                      __typename: newData.bslJobFairResumePage.__typename
                    }
                  };
                }
              };

              return this.props.children(
                QueryAllResumes.STATUS.LOADED,
                null,
                data,
                () => result.fetchMore(fetchMoreSpecs)
              );
            default:
              console.error("Unexpected network status for query");
              return this.props.children(
                QueryAllResumes.STATUS.ERROR,
                null,
                {},
                null
              );
          }
        }}
      </Query>
    );
  }
}

export default QueryAllResumes;
