import RouterUtils from "../../utils/RouterUtils";
import * as ROUTES from "../../constants/routes";

class JobManagementRoutes {
  static getJobIdFromRoute(match) {
    return match.params.jobId;
  }

  static getCompanyIdFromRoute(match) {
    return match.params.companyId;
  }

  static getOpeningAddRoute(match) {
    return RouterUtils.injectParamsInRoute(ROUTES.JOB_OPENING_ADD, {
      companyId: JobManagementRoutes.getCompanyIdFromRoute(match),
    })
  }

  static getPreviewRoute(match, jobId) {
    return RouterUtils.injectParamsInRoute(ROUTES.JOB_PREVIEW, {
      companyId: JobManagementRoutes.getCompanyIdFromRoute(match),
      jobId: jobId
    })
  }

  static getDefaultRoute(companyId) {
    return RouterUtils.injectParamsInRoute(ROUTES.JOB_MANAGEMENT, {companyId});
  }
}

export default JobManagementRoutes;
