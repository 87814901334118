import React from "react";
import PrivatePage from "../page/PrivatePage";
import {Trans, withTranslation} from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AdminFairUpdateForm from "./AdminFairUpdateForm";
import QueryJobFairById from "./QueryJobFairById";
import {withRouter} from "react-router-dom";
import LoadingSpinner from "../loadingspinner/LoadingSpinner";
import LoadingDataError from "../common/LoadingDataError";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import AdminFairBoothForm from "./AdminFairBoothForm";

class AdminFairUpdatePage extends React.Component {
  showLoading = () => {
    return <LoadingSpinner/>;
  };

  showError = () => {
    return <LoadingDataError/>;
  };

  render() {
    const t = this.props.t;
    const { id } = this.props.match.params;

    const detailsTabTitle = (
      <Trans i18nKey="admin:fair_details_tab">
        Details
      </Trans>
    );

    const companiesTabTitle = (
      <Trans i18nKey="admin:fair_companies_tab">
        Companies
      </Trans>
    );

    return (
      <PrivatePage>
        <QueryJobFairById id={id} onLoading={this.showLoading} onError={this.showError} onLoaded={(jobFair) => {

          if (!jobFair)
            return t("admin:fair_cannot_find");

          return (
            <React.Fragment>
              <h1>{jobFair.title}</h1>
              <Row>
                <Col>
                  <Tabs defaultActiveKey="details" id="fair-update-tabs">
                    <Tab eventKey="details" title={detailsTabTitle} mountOnEnter>
                      <AdminFairUpdateForm fair={jobFair}/>
                    </Tab>
                    <Tab eventKey="companies" title={companiesTabTitle} mountOnEnter>
                      <AdminFairBoothForm fair={jobFair}/>
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </React.Fragment>
          );
        }}/>
      </PrivatePage>
    );
  }
}



export default withTranslation()(withRouter(AdminFairUpdatePage));
