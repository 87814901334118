import React from "react";
import "./JobOpeningsListItem.scss";
import JobTitle from "./JobTitle";

class JoOpeningsListItem extends React.Component {

  onClickItem = (event) => {
    event.preventDefault();
    this.props.selectOpening();
  };

  render() {
    return (
      <div className="JobOpeningsListItem">
        <SelectableItem selected={this.props.selected} onClickItem={this.onClickItem}>
          <div className="opening-title">
            <JobTitle title={this.props.jobItem.title}/>
          </div>
        </SelectableItem>
      </div>
    );
  }
}

class SelectableItem extends React.Component {
  render() {
    return (
      <div className={"SelectableItem " + (this.props.selected ? "selected" : "unselected")} onClick={this.props.onClickItem}>
        {this.props.children}
      </div>
    );
  };
}

export default JoOpeningsListItem;
