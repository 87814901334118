import React from "react";
import Page from "../page/Page";
import Firebase from "./Firebase";
import FirebaseContext from "./FirebaseContext";
import {withTranslation} from "react-i18next";
import LanguageUtils from "../../utils/LanguageUtils";

/**
 * Context provider that can be consumed by withFirebase to get current Firebase authentication server connection.
 * Environment variables are read at construction time. If they are invalid, an error page is displayed.
 * The user can't go further and the app has to be fixed. Does not detect connection errors or invalid urls/credentials
 * in configuration because no connection is attempted yet.
 */
class FirebaseProvider extends React.Component {

  constructor(props) {
    super(props);

    // Get a new Firebase instance
    this.firebase = new Firebase();

    // Set Firebase language and store it in state to monitor changes
    const currentLanguage = LanguageUtils.getCurrentLanguageCode(this.props.i18n);
    this.state = {language: currentLanguage};
    this.firebase.setLanguageCode(currentLanguage);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // If i18n language has changed, update state with this language and update Firebase instance
    const currentLanguage = LanguageUtils.getCurrentLanguageCode(this.props.i18n);
    if (currentLanguage !== prevState.language) {
      this.firebase.setLanguageCode(currentLanguage);
      this.setState({language: currentLanguage});
    }
  }

  render() {
    const t = this.props.t;

    // If an error occurred during Firebase initialization, it means the configuration is bad. It is not a temporary
    // connection problem. User can't go further.
    if (this.firebase.lastError) {
      return (
        // We need to include <Page> because this component is called outside a Page component in the App component
        <Page>
          <p>{t("common:firebase_error_msg_1")}</p>
          <p>{this.firebase.lastError.message}</p>
          <p>{t("common:firebase_error_msg_2")}</p>
        </Page>
      );
    }

    // Return a Firebase connection provider
    return (
      <FirebaseContext.Provider value={this.firebase}>
        {this.props.children}
      </FirebaseContext.Provider>
    );
  }
}

export default withTranslation()(FirebaseProvider);
