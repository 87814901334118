import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {withTranslation} from "react-i18next";
import "./CandidateSearchPane.scss";
import JobFairFilter from "./JobFairFilter";

class CandidateSearchPane extends React.Component {

  updateGeneral = general => {
    this.props.updateSearchFilters({ general: general });
  };

  updateJobFairId = jobFairId => {
    this.props.updateSearchFilters({ jobFairId: jobFairId });
  };

  render() {
    const t = this.props.t;

    return (
      <Card className="CandidateSearchPane">
        <Card.Header>{t("jobmanagement:candidate_filter_title")}</Card.Header>
        <Card.Body className="spaced-children">
          {/*
          <Row className="filter-row">
            <Col>
              <GeneralFilter
                updateSelectedValues={this.updateGeneral}
                selectedValues={this.props.searchFilters.general}
              />
            </Col>
          </Row>
          */}
          <Row className="filter-row">
            <Col>
              <JobFairFilter
                searchFilters={this.props.searchFilters}
                updateSelectedValues={this.updateJobFairId}
                selectedValues={this.props.searchFilters.jobFairId}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

export default withTranslation()(CandidateSearchPane);
