import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import FormText from "react-bootstrap/FormText";
import React from "react";
import withFirebase from "../firebase/withFirebase";
import withAuthUser from "../session/withAuthUser";
import "./LoginModal.scss";
import {withTranslation} from "react-i18next";

class LoginModal extends React.Component {
  state = {
    currentPassword: "",
    error: null,
  };

  onSubmit = (event) => {

    event.preventDefault();

    // Hide previous errors, then perform login
    this.setState({error: null}, () => {
      this.props.firebase
        .doSignInWithEmailAndPassword(this.props.authUser.firebase.email, this.state.currentPassword)
        .then(() => {
          return this.props.onComplete();
        })
        .catch(error => {
          this.setState({error: error});
        });
    });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render () {
    const t = this.props.t;

    return (
      <Modal show={this.props.show} onHide={this.props.handleClose} className="LoginModal">
        <Modal.Body>
          <p>{t("settings:login_type_password")}</p>
          <Form onSubmit={this.onSubmit}>
            <FormGroup>
              <FormControl
                name="currentPassword"
                value={this.state.currentPassword}
                onChange={this.onChange}
                type="password"
                placeholder={t("settings:login_password_placeholder")}
              />
            </FormGroup>
            <FormGroup className={"button-row"}>
              <Button type="submit">
                {t("settings:login_ok_button")}
              </Button>
              <Button type="button" variant="secondary" onClick={this.props.handleClose}>
                {t("settings:login_cancel_button")}
              </Button>
            </FormGroup>
            {this.state.error && <FormText as="div" className="form-error">{this.state.error.message}</FormText>}
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withTranslation()(withFirebase(withAuthUser(LoginModal)));
