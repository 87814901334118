import LANGUAGES from "../constants/languages";

class LanguageUtils {

  /**
   * Finds current language in the list of defined languages (LANGUAGES).
   * For each language of the resolving chain used by i18n, check if it is in the list of LANGUAGES. Stop as soon as one is
   * found, it means it is the more specific. LANGUAGES[0] will always be somewhere in the resolving chain
   * because it is the fallback language, but it might not be the more specific.
   * @param i18n i18next object (usually from this.props.i18n of a component wrapped with withTranslation)
   * @return {string} Language code among those in LANGUAGES
   */
  static getCurrentLanguageCode(i18n) {
    return i18n.languages.find((language) => Object.keys(LANGUAGES).includes(language));
  }

  /**
   * Gets the language name of a language code, as defined in LANGUAGES
   * @param languageCode Language code to search for
   * @return {string} Language name in plain English, or French, etc.
   */
  static getLanguageName(languageCode) {
    return LANGUAGES[languageCode] ? LANGUAGES[languageCode] : languageCode;
  }

  /**
   * Gets a list of languages available other than the current language. The possible set of languages is defined
   * in LANGUAGES.
   * @param i18n i18next object (usually from this.props.i18n of a component wrapped with withTranslation)
   * @return {*} Array of languages (code: language code, name: language name)
   */
  static getOtherLanguages(i18n) {
    const currentLanguageCode = LanguageUtils.getCurrentLanguageCode(i18n);
    return Object.keys(LANGUAGES).filter(key => key !== currentLanguageCode).map(key => ({
      code: key,
      name: LANGUAGES[key],
    }));
  }
}

export default LanguageUtils;
