import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import withListItemHandlers from "../common/withListItemHandlers";
import withButton from "../materialicon/withButton";
import DeleteIcon from "@material-ui/icons/Delete";
import StringUtils from "../../utils/StringUtils";
import "./BoothListItem.scss";

class BoothListItem extends React.Component {

  /**
   * Call the parent to delete this item from the parent's list of items
   * @param event
   */
  onClickDelete = event => {
    event.preventDefault();
    this.props.deleteItem();
  };

  render() {
    const selectedValue = StringUtils.nullToEmpty(this.props.item.companyId);

    return (
      <div className="BoothListItem">
        <Row>
          <Col xs="2">
            <FormControl
              className="number-input"
              name={`${this.props.namePrefix}boothNumber`}
              onChange={this.props.onChangeInput}
              type="text"
              value={StringUtils.nullToEmpty(this.props.item.boothNumber)}
            />
          </Col>
          <Col>
            <Form.Control
              as="select"
              name={`${this.props.namePrefix}companyId`}
              onChange={this.props.onChangeInput}
              value={selectedValue}
            >
              <option key="blank" value=""/>
              {this.props.companies.map(company =>
                <option
                  key={company._id}
                  value={company._id}
                >
                  {`${company.name} (${company.email})`}
                </option>
              )}
            </Form.Control>
          </Col>
          <Col xs="auto" className="icon-column">
            <WithButtonDeleteIcon onClick={this.onClickDelete}/>
          </Col>
        </Row>
      </div>
    );
  }
}

const WithButtonDeleteIcon = withButton(DeleteIcon);
export default withListItemHandlers(BoothListItem);
