/**
 * Utilities to know whether file format is supported by FileConverter class (in GraphQL).
 * A copy of this class exists in GraphQL package and webapp packages, until we move it into its own dependency.
 *
 * ANY MODIFICATION TO THIS FILE SHOULD BE DONE IN ALL COPIES.
 */
class FileConverterSupportedFormats {
  /**
   * Return whether file has a PDF extension or a variation
   * @param filename
   * @returns {boolean} True if file has a PDF-like extension
   */
  static isPdf(filename) {
    return /^pdf$/i.test(FileConverterSupportedFormats.getExtension(filename));
  }

  /**
   * Return whether file has a docx extension or a variation
   * @param filename
   * @returns {boolean} True if file has a Word-like extension
   */
  static isWord(filename) {
    return /^docx$/i.test(FileConverterSupportedFormats.getExtension(filename));
  }

  /**
   * Return whether file has a text extension
   * @param filename
   * @returns {boolean} True if file has a text extension
   */
  static isText(filename) {
    return /^txt$/i.test(FileConverterSupportedFormats.getExtension(filename));
  }

  /**
   * Return whether the filename has a format that is supported for conversion by the current converter.
   * @param filename
   * @returns {boolean} True if file extension is supported
   */
  static isFormatSupported(filename) {
    return FileConverterSupportedFormats.isPdf(filename) || FileConverterSupportedFormats.isWord(filename) || FileConverterSupportedFormats.isText(filename);
  }

  /**
   * Get filename extension
   * @param filename
   * @returns {string} File extension
   */
  static getExtension(filename) {
    return filename !== undefined ? filename.split(`.`).pop() : "";
  }

}

export default FileConverterSupportedFormats;
