import React from "react";
import {withTranslation} from "react-i18next";
import StringUtils from "../../utils/StringUtils";
import "./JobTitle.scss";

class JobTitle extends React.Component {
  render() {
    const t = this.props.t;

    return StringUtils.isNullOrEmpty(this.props.title) ?
      <span className="JobTitle empty">{t("jobmanagement:job_preview_no_title")}</span> :
      this.props.title;
  }
}

export default withTranslation()(JobTitle);
