import pathToRegexp from "path-to-regexp";

class RouterUtils {

  /**
   * Replaces the parameters in a React Router URL of the form '/.../:param/...' with their actual value
   * @param route URL pattern with :parameters
   * @param params Values for parameters
   * @returns {string} Actual URL
   */
  static injectParamsInRoute(route, params) {
    const compiledRoute = pathToRegexp.compile(route);
    return compiledRoute(params);
  };

}

export default RouterUtils;
