import React from "react";
import "./JobPreview.scss";
import Form from "react-bootstrap/Form";
import {withTranslation} from "react-i18next";
import JobTitle from "./JobTitle";
import FormControl from "react-bootstrap/FormControl";
import gql from "graphql-tag";
import {Mutation} from "react-apollo";
import QueryCurrentJobsForCompany from "./QueryCurrentJobsForCompany";
import SuccessModal from "../common/SuccessModal";
import ErrorModal from "../common/ErrorModal";
import SpinnerButton from "../loadingspinner/SpinnerButton";
import Button from "react-bootstrap/Button";
import StringUtils from "../../utils/StringUtils";
import JobManagementRoutes from "./JobManagementRoutes";
import {withRouter} from "react-router-dom";

class JobPreviewForm extends React.Component {

  static UPDATE_JOB_OPENING = gql`
    mutation companyUpdateJobFromText($companyId: String!, $job: CompanyJobInput!) {
      companyUpdateJobFromText(companyId: $companyId, job: $job) {
        _id
      }
    }
  `;

  state = {
    title: "",
    fullText: "",
    showSuccess: false,
    error: null
  };

  componentDidMount() {
    this.setStateFromJob();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.job._id !== this.props.job._id) {
      this.setStateFromJob();
    }
  }

  hideSuccess = () => {
    this.setState({
      showSuccess: false
    });
    this.props.exitModifyMode();
  };

  hideError = () => {
    this.setState({ error: null });
  };

  setStateFromJob = () => {
    this.setState({
      title: this.props.job.title,
      fullText: this.props.job.fullText,
    });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  canSubmit = () => {
    return this.state.title.trim().length > 2;
  };

  saveJobOpening = (mutationCallback) => {

    // The endpoint doesn't touch the other fields (except those resulting from the analysis)
    const newJob = {
      _id: this.props.job._id,
      title: this.state.title,
      fullText: this.state.fullText
    };

    return mutationCallback({
      variables: {
        companyId: JobManagementRoutes.getCompanyIdFromRoute(this.props.match),
        job: newJob,
      }
    });
  };

  onSubmit = (event, mutationCallback) => {
    const t = this.props.t;

    event.preventDefault();

    // Hide previous errors, then perform update
    this.setState({ error: null }, () => {
      this.saveJobOpening(mutationCallback)
        .then((result) => {
          const newJob = result.data["companyUpdateJobFromText"];

          if (!newJob) {
            return Promise.reject();
          }

          this.setState({showSuccess: true});
          }
        )
        .catch((error) => {
          let errorMsg = t("jobmanagement:job_preview_modify_error");
          if (error.message) {
            errorMsg = errorMsg + " " + t("jobmanagement:server_error", {serverError: error.message});
          }
          this.setState({error: errorMsg});
        });
    });
  };

  render() {
    const t = this.props.t;
    const modify = this.props.modify;

    return (
      <div className="JobPreview">
        <Mutation
          mutation={JobPreviewForm.UPDATE_JOB_OPENING}
          refetchQueries={[
            {
              query: QueryCurrentJobsForCompany.GET_JOBS_FOR_COMPANY,
              variables: {
                _id: JobManagementRoutes.getCompanyIdFromRoute(this.props.match)
              }
            }
          ]}
        >
          {(mutationCallback, { loading }) => (
            <Form
              onSubmit={event => {
                this.onSubmit(event, mutationCallback);
              }}
            >
              {/* The modal is visible only when form has been submitted successfully */}
              <SuccessModal
                show={this.state.showSuccess}
                handleClose={this.hideSuccess}
              >
                <p>{t("jobmanagement:job_preview_update_success_msg")}</p>
              </SuccessModal>
              {/* The modal is visible only when there are errors after form has been submitted */}
              <ErrorModal
                show={this.state.error !== null}
                handleClose={this.hideError}
              >
                <p>{this.state.error}</p>
              </ErrorModal>
              <Form.Group>
                {modify ?
                  <FormControl
                    name="title"
                    type="text"
                    value={this.state.title}
                    onChange={this.onChange}
                    placeholder={t("jobmanagement:job_preview_title_placeholder")}
                  />
                  :
                  <h1><JobTitle title={this.state.title}/></h1>
                }
              </Form.Group>
              <Form.Group controlId="description">
                {modify && <Form.Text>{t("jobmanagement:add_job_text_area_cannot_modify")}</Form.Text>}
                {!modify &&
                <React.Fragment>
                  <Form.Label>{t("jobmanagement:job_preview_full_text_label")}</Form.Label>
                  <Form.Control
                    name="fullText"
                    as="textarea"
                    rows="10"
                    value={StringUtils.nullToEmpty(this.state.fullText) /*Textarea cannot accept null string*/}
                    onChange={this.onChange}
                    placeholder={t("jobmanagement:add_job_text_area_placeholder")}
                    readOnly
                    />
                </React.Fragment>
                }
              </Form.Group>
              {modify &&
                <Form.Group className="button-row">
                  <SpinnerButton disabled={!this.canSubmit()} type="submit" loading={loading}>
                    {t("jobmanagement:job_preview_modify_ok")}
                  </SpinnerButton>
                  <Button type="button" variant="secondary" onClick={this.props.exitModifyMode}>
                    {t("jobmanagement:job_preview_cancel_button")}
                  </Button>
                </Form.Group>
              }
            </Form>
          )}
        </Mutation>
      </div>
    );
  }
}

export default withTranslation()(withRouter(JobPreviewForm));
