import React from "react";
import PrivatePage from "../page/PrivatePage";
import {withTranslation} from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import QueryJobFairs from "../jobmanagement/QueryJobFairs";
import LoadingSpinner from "../loadingspinner/LoadingSpinner";
import AdminList from "./AdminList";
import RouterUtils from "../../utils/RouterUtils";
import LoadingDataError from "../common/LoadingDataError";


class AdminFairListPage extends React.Component {
  render() {
    const t = this.props.t;
    return (
      <PrivatePage>
        <h1>{t("admin:fair_list_title")}</h1>
        <div className="spaced-children">
          <Row>
            <Col>
              <Link to={ROUTES.ADMIN_FAIR_CREATE}>
                {t("admin:fair_create")}
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <WithTranslationAdminFairList/>
            </Col>
          </Row>
        </div>
      </PrivatePage>
    );
  }
}

class AdminFairList extends React.Component {
  showLoading = () => {
    return <LoadingSpinner/>;
  };

  showError = () => {
    return <LoadingDataError/>;
  };

  render() {
    const t = this.props.t;

    return (
      <QueryJobFairs onLoading={this.showLoading} onError={this.showError} onLoaded={(jobFairs) =>
        <AdminList
          header={
            <Row>
              <Col xs={4}>
                {t("admin:fair_list_title_col")}
              </Col>
              <Col xs={4}>
                {t("admin:fair_list_city_col")}
              </Col>
              <Col xs={2}>
                {t("admin:fair_list_dateBegin_col")}
              </Col>
              <Col xs={2}>
                {t("admin:fair_list_dateEnd_col")}
              </Col>
            </Row>
          }
          content={
            jobFairs.map(jobFair =>
              <Row key={jobFair._id}>
                <Col xs={4}>
                  <Link to={RouterUtils.injectParamsInRoute(ROUTES.ADMIN_FAIR_UPDATE, {id: jobFair._id})}>{jobFair.title}</Link>
                </Col>
                <Col xs={4}>
                  {jobFair.city}
                </Col>
                <Col xs={2}>
                  {jobFair.dateBegin}
                </Col>
                <Col xs={2}>
                  {jobFair.dateEnd}
                </Col>
              </Row>
            )
          }/>
      }/>
    );
  }
}

const WithTranslationAdminFairList = withTranslation()(AdminFairList);
export default withTranslation()(AdminFairListPage);
