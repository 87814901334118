import React from "react";
import QueryCompany from "./QueryCompany";
import AuthUserContext from "./AuthUserContext";
import {withApollo} from "@apollo/react-hoc";
import LoadingSpinner from "../loadingspinner/LoadingSpinner";
import withFirebaseUser from "./withFirebaseUser";
import Page from "../page/Page";
import withFirebase from "../firebase/withFirebase";
import {withTranslation} from "react-i18next";
import "./AuthUserProvider.scss";

/**
 * Context provider that can be consumed by withAuthUser to get current logged user with its profile.
 *
 * When not authenticated, either current user is null, or current user is not null but
 * its isAuthenticated property is false.
 *
 * When authenticated, current user is not null and its isAuthenticated property is true.
 *
 * User profile comes from database if user is authenticated. If user is not authenticated, some properties of
 * profile may nevertheless be set to keep settings of anonymous user.
 *
 * Current user is an object with the following properties:
 *
 * <ul>
 *   <li>isAuthenticated: true if user is authenticated, false otherwise (user is not null when not authenticated)
 *   <li>firebase: for all properties returned by Firebase</li>
 *   <li>profile: if authenticated: all properties coming from our own database of users;
 *                if not authenticated: some properties may be set throughout navigation</li>
 *   <li>setProfile: callback to call when modifying the user profile
 *   <li>refresh: to force a reload when a new user has been created in the database of users or the current user
 *                has been modified in the database of users</li>
 *   <li>enableFirebaseListener: listen to the onAuthStateChanged Firebase event. Required only because we don't
 *                               want to trigger it right after a user is created in Firebase but has not yet
 *                               been created in the database of users</li>
 *   <li>disableFirebaseListener: do not listen to the onAuthStateChanged Firebase event. Required for the same
 *                                reason as enableFirebaseListener.</li>
 *   <li>setFirebaseUser: callback to call when signing up to signal a change in the current Firebase user.
 *                        Required only because we don't want to trigger the onAuthStateChanged Firebase event
 *                        right after a user is created in Firebase but has not yet been created in the database of users.
 * </ul>
 *
 * @param Component
 * @returns {function(*): *}
 */
class AuthUserProvider extends React.Component {

  state = {
    anonymousProfile: {}
  };

  showLoading = () => {
    // We need to include <Page> because this component is called outside a Page component in the App component
    return <Page><LoadingSpinner/></Page>;
  };

  showMissingProfileError = () => {

    // If browser cache indicates that user is logged in but GraphQL returns no profile, unlog user to avoid being
    // caught in an intermediate state where user can't view any page (even signout) because the failure of
    // <QueryCompany> prevents the rendering of any of its child.
    // Wait 10 seconds before doing it so that user can read error message
    setTimeout(() => this.props.firebase.doSignOut(), 10000);

    // Display an error message while the doSignOut triggers the logout event that redirects to the sign in page.
    // We need to include <Page> because this component is called outside a Page component in the App component
    return (
      <Page>
        <div className="AuthUserProvider">
          {this.props.t("session:missing_profile_msg_1")}
          <br/>
          {this.props.t("session:missing_profile_msg_2")}
          <br/>
          {this.props.t("session:missing_profile_msg_3")}
        </div>
      </Page>
    );
  };

  /**
   * Set user profile directly in memory, not in database. To be used to save temporary data for anonymous users
   * @param profile Updated profile
   */
  setProfile = (profile) => {
    this.setState({
      anonymousProfile: profile,
    });
  };

  render() {

    // User is not signed in to Firebase, return an anonymous profile stored only in memory (as long as the current
    // component is not destroyed and recreated)
    if (!this.props.firebaseUser.firebase) {
      return (
        <EnhancedUser
          firebaseUser={this.props.firebaseUser}
          refresh={() => {}}
          profile={this.state.anonymousProfile}
          setProfile={this.setProfile}
        >
          {this.props.children}
        </EnhancedUser>
      );
    }

    // User is signed in to Firebase. Query profile in database and return complete user information.
    return (
      <QueryCompany
        email={this.props.firebaseUser.firebase.email}
        onLoading={this.showLoading}
        onError={this.showMissingProfileError}
        onLoaded={(profile, refetch) => {
          // If profile is null, we have an inconsistency
          if (profile === null)
            return this.showMissingProfileError();

          return (
            <EnhancedUser
              firebaseUser={this.props.firebaseUser}
              refresh={refetch}
              profile={profile}
              setProfile={this.setProfile}
            >
              {this.props.children}
            </EnhancedUser>
          );
        }}
      />
    );
  }
}

class EnhancedUser extends React.Component {
  render() {
    // Build the complete user object to return
    const enhancedAuthUser = {
      enableFirebaseListener: this.props.firebaseUser.enableFirebaseListener,
      disableFirebaseListener: this.props.firebaseUser.disableFirebaseListener,
      setFirebaseUser: this.props.firebaseUser.setFirebaseUser,
      getFirebaseUserIdToken: this.props.firebaseUser.getFirebaseUserIdToken,
      refresh: this.props.refresh,
      isAuthenticated: this.props.profile !== null && this.props.firebaseUser.firebase !== null,
      firebase: this.props.profile ? this.props.firebaseUser.firebase : null,
      profile: this.props.profile,
      setProfile: this.props.setProfile,
    };

    // Return a user provider
    return (
      <AuthUserContext.Provider value={enhancedAuthUser}>
        {this.props.children}
      </AuthUserContext.Provider>
    );
  }
}

export default withTranslation()(withFirebaseUser(withFirebase(withApollo(AuthUserProvider))));
