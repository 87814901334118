import React from "react";
import AuthUserContext from "./AuthUserContext";

/**
 * Higher Order Component that fills the authUser prop of wrapped component with the current authenticated user.
 *
 * @returns {function(*): *}
 */
function withAuthUser(Component) {
  return function(props) {

    // Beware that there are cases where withAuthUser is used outside the AuthUserProvider component
    // e.g. when displaying an error page when dealing with the Firebase user, so authUser might be null.
    return (
      <AuthUserContext.Consumer>
        {authUser => <Component authUser={authUser} {...props}/>}
      </AuthUserContext.Consumer>
    );
  }
}

export default withAuthUser;
