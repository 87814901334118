import React from "react";
import JobDropbox from "./JobDropbox";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./AddJobTab.scss";
import {withTranslation} from "react-i18next";
import AddJobTextAreaForm from "./AddJobTextAreaForm";

class AddJobTab extends React.Component {

  render() {
    const t = this.props.t;

    return (
      <div className="AddJobTab spaced-children">
        <Row>
          <Col>
            <h1>{t("jobmanagement:add_job_tab_title")}</h1>
          </Col>
        </Row>
        <Row className="drop-row">
          <Col>
            <JobDropbox />
          </Col>
        </Row>
        <Row>
          <Col>
            <hr/>
          </Col>
          <Col xs="auto">
            {t("jobmanagement:add_job_tab_or")}
          </Col>
          <Col>
            <hr/>
          </Col>
        </Row>
        <Row>
          <Col>
            <AddJobTextAreaForm/>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(AddJobTab);
