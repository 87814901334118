import React from "react";
import "./AdminList.scss";

class AdminList extends React.Component {
  render() {
    return (
      <div className="AdminList">
        <div className="header">
          {this.props.header}
        </div>
        <div className="content">
          {this.props.content}
        </div>
      </div>
    );
  }
}

export default AdminList;
