import React from 'react';
import "./FullPageSuspense.scss";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class FullPageSuspense extends React.Component {

  render() {
    return (
      <Container className="FullPageSuspense">
        <Row>
          <Col>
            <Spinner animation="border" variant="secondary" role="status"/>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default FullPageSuspense;
