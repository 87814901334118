import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import withListItemHandlers from "../common/withListItemHandlers";
import Card from "react-bootstrap/Card";
import "./CandidateListItem.scss";
import ExpandableSection from "../common/ExpandableSection";
import DeleteIcon from "@material-ui/icons/Block";
import FavoriteOnIcon from "@material-ui/icons/Star";
import FavoriteOffIcon from "@material-ui/icons/StarBorder";
import withButton from "../materialicon/withButton";
import PillList from "../common/PillList";
import StringUtils from "../../utils/StringUtils";
import TypeUtils from "../../utils/TypeUtils";
import {withTranslation} from "react-i18next";
import DownloadResumeButton from "./DownloadResumeButton";
import DateFormatters from "../../utils/DateFormatters";

/**
 * A candidate for a job opening.
 * As an item of a list, it inherits some properties of withListHandlers.
 */
class CandidateListItem extends React.Component {

  /**
   * Replaces "Not found" and "Not Found" strings with an empty string. Replaces null strings with an empty string.
   * @param string String to process
   */
  static cleanString(string) {
    if (string === "Not found" || string === "Not Found")
      return "";

    return StringUtils.nullToEmpty(string);
  }

  /**
   * Replaces empty strings with a placeholder "?"
   * @param string String to process
   */
  static withPlaceholder(string) {
    return StringUtils.isNullOrEmpty(string) ? "?" : string;
  }

  render() {
    const t = this.props.t;
    const opening = this.props.opening;
    const resume = this.props.item.resume;
    const jobFair = this.props.item.jobFair;

    const firstName = CandidateListItem.cleanString(resume.firstName);
    const lastName = CandidateListItem.cleanString(resume.lastName);
    const jobTitle = CandidateListItem.cleanString(resume.jobTitle);
    const email = CandidateListItem.cleanString(resume.email);
    const phone = CandidateListItem.cleanString(resume.phone);

    const fullName = CandidateListItem.withPlaceholder([firstName, lastName].join(" ").trim());

    const hasResume = resume.resumeFile && !StringUtils.isNullOrEmpty(resume.resumeFile.filename);

    return (
      <div className="CandidateListItem">
        <Card>
          <Card.Body className="spaced-children">
            <Row>
              <Col className="name-col">
                {this.props.itemNumber}. {fullName}
              </Col>
              <Col xs="auto" className="icon-column">
                <DownloadResumeButton
                  resumeId={this.props.item.resume._id}
                  disabled={!hasResume}/>
              </Col>
              {/*
              <Col xs="auto" className="icon-column">
                {resume.favorite ?
                  <WithButtonFavoriteOnIcon title={t("jobmanagement:candidate_remove_from_favorites_icon_title")}/> :
                  <WithButtonFavoriteOffIcon title={t("jobmanagement:candidate_add_to_favorites_icon_title")}/>}
              </Col>
              <Col xs="auto" className="icon-column">
                <WithButtonDeleteIcon onClick={this.props.deleteItem} title={t("jobmanagement:candidate_hide_from_list_icon_title")}/>
              </Col>
              */}
            </Row>
            {jobFair.title &&
            <Row>
              <Col>
                {jobFair.title} {!StringUtils.isNullOrEmpty(jobFair.city) && <span>({jobFair.city})</span>}
              </Col>
              {resume.updated &&
              <Col xs="auto">
                {DateFormatters.formatDateWithTime(resume.updated)}
              </Col>
              }
            </Row>
            }
            {jobTitle &&
            <Row>
              <Col>
                {jobTitle.substring(0, 50)}
              </Col>
            </Row>
            }
            {(email || phone) &&
            <Row>
              <Col>
                {email.substring(0, 50)}
              </Col>
              <Col xs="auto">
                {phone.substring(0, 20)}
              </Col>
            </Row>
            }
            <Row>
              <Col>
                <ExpandableSection header={opening ? t("jobmanagement:candidate_skill_match_section_title") : t("jobmanagement:candidate_skill_section_title")}>
                  <WithTranslationSkillMatchSection
                    opening={opening}
                    resume={resume}
                  />
                </ExpandableSection>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

class SkillMatchSection extends React.Component {
  render() {
    const t = this.props.t;
    const opening = this.props.opening;
    const resume = this.props.resume;

    const requiredTechnicalSkills = opening ? PillList.formatStrings(TypeUtils.ensureArray(opening.nlpRequiredTechnicalSkills)) : [];
    const candidateTechnicalSkills = PillList.formatStrings(
      TypeUtils.ensureArray(resume.technicalSkills).reduce((acc, skillGroup) =>
          acc.concat(TypeUtils.ensureArray(skillGroup.skills).map(skill => skill.name)),
        []));
    const requiredSoftSkills = opening ? PillList.formatStrings(TypeUtils.ensureArray(opening.nlpRequiredSoftSkills)) : [];
    const candidateSoftSkills = PillList.formatStrings(TypeUtils.ensureArray(resume.softSkills).map(softSkill => softSkill.name));

    return (
      <div className="spaced-children skill-section">
        <SkillMatch
          header={t("jobmanagement:candidate_technical_skills")}
          requiredSkills={requiredTechnicalSkills}
          candidateSkills={candidateTechnicalSkills}
        />
        <SkillMatch
          header={t("jobmanagement:candidate_soft_skills")}
          requiredSkills={requiredSoftSkills}
          candidateSkills={candidateSoftSkills}
        />
      </div>

    );
  }
}

class SkillMatch extends React.Component {
  render() {
    return (
      <div className="SkillMatch spaced-children">
        <Row>
          <Col className="label">
            {this.props.header}
          </Col>
        </Row>
        <Row>
          <Col>
            <PillList items={this.props.candidateSkills} variant="skill" altVariant="skill-match"
                      altTest={(item) => this.props.requiredSkills.includes(item)}/>
          </Col>
        </Row>
      </div>
    );
  }
}

const WithButtonDeleteIcon = withButton(DeleteIcon);
const WithButtonFavoriteOnIcon = withButton(FavoriteOnIcon);
const WithButtonFavoriteOffIcon = withButton(FavoriteOffIcon);
const WithTranslationSkillMatchSection = withTranslation()(SkillMatchSection);

export default withTranslation()(withListItemHandlers(CandidateListItem));
