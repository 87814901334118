import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./FilterOption.scss";
import CheckedIcon from "@material-ui/icons/CheckBox";
import UncheckedIcon from "@material-ui/icons/CheckBoxOutlineBlank";

class FilterOption extends React.Component {
  render() {
    return (
      <Row className="FilterOption" onClick={this.props.onClick}>
        <Col xs="auto" className="icon-col">
          {this.props.checked ? <CheckedIcon/> : <UncheckedIcon/>}
        </Col>
        <Col className="option-col">
          {this.props.children}
        </Col>
      </Row>
    );
  }
}

export default FilterOption;
