import React from "react";
import Footer from "../footer/Footer";
import Menu from "../menu/Menu";
import Container from "react-bootstrap/Container";
import "./Page.scss";
import scrollToComponent from "react-scroll-to-component";
import ScrollToTopOnMount from "./ScrollToTopOnMount";
import LoadingSpinner from "../loadingspinner/LoadingSpinner";

class Page extends React.Component {

  state = {
    pageSuspense: false,
  };

  setPageSuspense = (pageSuspense) => {
    this.setState({
      pageSuspense
    })
  };

  render() {
    return (
      <div className="Page">
        <ScrollToTopOnMount/>
        <nav>
          <Menu setSuspense={this.setPageSuspense}/>
        </nav>
        {this.state.pageSuspense && <LoadingSpinner/>}
        {!this.state.pageSuspense &&
          <div className="scrollable-content">
            <Container className="main-container">{this.props.children}</Container>
            <footer>
              <Footer/>
            </footer>
          </div>
        }
      </div>
    );
  }

  /**
   * Scroll page so that element passed as parameter appears on top. Take into account the height of the
   * fixed-top menu so that the element is not behind the menu. This function assumes that there is a menu
   * (the HTML tag is not important) with a class "fixed-top".
   * @param scrollTo React reference to the element to bring on top.
   * @param duration Duration of animation in ms
   */
  static scrollToComponentAfterMenu = (scrollTo, duration = 500) => {
    if (scrollTo) {
      const fixedTopElements = document.getElementsByClassName('fixed-top');
      // If there is one fixed element on page (such as the menu), scroll after the menu, otherwise
      // scroll to the very top of page.
      // Ensure that there is a little space (1 px) between the top and the element to scroll to top so
      // that the element doesn't touch the top
      const offset = fixedTopElements.length > 0 ? fixedTopElements[0].clientHeight + 1: 1;
      scrollToComponent(scrollTo, {offset: -offset, align: 'top', duration: duration});
    }
  };
}

export default Page;
