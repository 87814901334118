import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";

class QueryCompanyJobTotals extends React.Component {
  static GET_COMPANY_JOB_TOTALS = gql`
    query companyJobTotals {
      companyJobTotals {
        _id
        name
        email
        jobTotal
      }
    }
  `;

  render() {
    return (
      <Query
        query={QueryCompanyJobTotals.GET_COMPANY_JOB_TOTALS}
      >
        {result => {
          if (result.loading) return this.props.onLoading();
          if (result.error) return this.props.onError();
          return this.props.onLoaded(result.data["companyJobTotals"], result.refetch);
        }}
      </Query>
    );
  }
}

export default QueryCompanyJobTotals;
