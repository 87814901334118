import React from "react";
import {withRouter} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import {Redirect} from "react-router-dom";

/**
 * Redirect to the referrer location specified in the state of the current location, typically after
 * Sign In or Sign Out.
 *
 * Redirect to HOME if there is no referrer or if current location is the same as the referrer
 * (in case referrer is Sign In or Sign Out).
 */
class RedirectToReferrer extends React.Component {
  render () {
    const referrerPathname = (this.props.location.state && this.props.location.state.referrer ?
      this.props.location.state.referrer.pathname : null);
    const referrerSearch = (this.props.location.state && this.props.location.state.referrer ?
      this.props.location.state.referrer.search : null);
    const locationAsString = this.props.location.pathname + this.props.location.search;
    const referrerAsString = referrerPathname + referrerSearch;
    const target = (referrerPathname && locationAsString !== referrerAsString ? this.props.location.state.referrer : ROUTES.HOME);

    return <Redirect to={target}/>;
  }
}

export default withRouter(RedirectToReferrer);
