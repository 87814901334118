import React from "react";
import withButton from "./withButton";
import "./withRoundButton.scss";

/**
 * Higher order component that takes a component as argument and returns another component.
 * This HOC is intended to wrap a component that is a Material UI icon. It makes the component clickable
 * by wrapping it with a link-type Bootstrap Button and it uses style to make a round background.
 * @param WrappedComponent
 * @returns {}
 */
function withRoundButton(WrappedComponent) {

  const WithButtonComponent = withButton(WrappedComponent);

  /**
   * Enhanced component to return
   */
  return class extends React.Component {

    render() {
      const {className, ...otherProps} = this.props;

      // Render the wrapped component inside a Button component. All props are passed to the Button component.
      return (
        <WithButtonComponent className={className + " withRoundButton"} {...otherProps}/>
      );
    }
  };
}

export default withRoundButton;
