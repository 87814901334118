import React from "react";
import { Link, withRouter } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { withAuthUser } from "../session";
import logo from "../../img/logo-maw-04-transparent.png";
import logo_portal from "../../img/bsl-cropped.png";
import * as ROUTES from "../../constants/routes";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Menu.scss";
import {withTranslation} from "react-i18next";
import LanguageUtils from "../../utils/LanguageUtils";
import JobManagementRoutes from "../jobmanagement/JobManagementRoutes";

class Menu extends React.Component {

  onClickLanguage = (event, languageCode) => {
    event.preventDefault();

    // Mark the whole page as loading
    this.props.setSuspense(true);

    // Tell i18next to fetch the files for the new language
    // Callback is called when other language has been fetched, then it resolves the promise (probably because the whole
    // promise is intended to be thrown and caught by the new Suspense component, but we don't use it yet because
    // using Suspense for loading state is experimental).
    this.props.i18n.changeLanguage(languageCode, (/*err*/) => {
      // Errors must be caught in the callback, they are not propagated to the promise catch clause
      // But do nothing if an error occurs (such as a missing translation namespace file), let i18next use fallbacks
    }).then(() => {
      // Whether an error occurred or not, stop the suspense that prevents the page from being rendered
      this.props.setSuspense(false);
    });
  };

  /**
   * Returns the underlying data structure for the language menu. It returns an object with the following properties:
   *  "title": the language object that serves as the title of the language menu
   *  "items": an array of language objects that are the menu subitems
   *
   *  A language object has the following properties:
   *  "name": the 2-letter name of the language, viewable by the user, not to be translated (eg. FR)
   *  "code": the code of the language, used by i18n to retrieve translations (eg. fr)
   *
   *  If there are no other languages than the current language, returns null.
   *  If there is only one other language, returns it as the menu title.
   *  If there are many other languages, returns the current language as the title and all other available languages
   *  as subitems.
   *
   *  @return Underlying data structure as describe above
   */
  getLanguageMenuStructure = () => {
    // Find current language in the list of defined languages (LANGUAGES).
    // For each language of the resolving chain used by i18n, check if it is in the list of LANGUAGES. Stop as soon as one is
    // found, it means it is the more specific. LANGUAGES[0] will always be somewhere in the resolving chain
    // because it is the fallback language, but it might not be the more specific.
    const currentLanguageCode = LanguageUtils.getCurrentLanguageCode(this.props.i18n);
    const currentLanguageName = LanguageUtils.getLanguageName(currentLanguageCode);

    // Alternate languages to select from
    const otherLanguages = LanguageUtils.getOtherLanguages(this.props.i18n);

    // If there is no other language, do not display menu item
    if (otherLanguages.length === 0)
      return null;

    // If there is only one alternative, display it as the menu item title (clickable), without menu subitems
    if (otherLanguages.length === 1)
      return ({
        "title": otherLanguages[0],
        "items": []
      });

    // If there are many alternatives, display the current language as the menu item title, then alternatives as subitems
    return ({
      "title": {
        "code": currentLanguageCode,
        "name": currentLanguageName
      },
      "items": otherLanguages
    })
  };

  render() {

    const t = this.props.t;

    // authUser might be null if we need to display an error page before AuthUserProvider is called
    const userIsAuthenticated = this.props.authUser ? this.props.authUser.isAuthenticated : false;

    // User is admin
    const canViewAdmin = !!(this.props.authUser && this.props.authUser.profile && this.props.authUser.profile.roles
      && this.props.authUser.profile.roles.includes('admin'));

    // User is admin or job fair organizer
    const canViewJobFairCompanies = !!(this.props.authUser && this.props.authUser.profile && this.props.authUser.profile.roles
      && this.props.authUser.profile.roles.some(role => ['admin', 'job-fair-organizer'].includes(role)));

    const companyId = (this.props.authUser && this.props.authUser.profile) ? this.props.authUser.profile._id : "";

    const languageMenuStructure = this.getLanguageMenuStructure();

    return (
      <div className="Menu">
        <Navbar fixed="top" bg="light" variant="light" expand="md">
          <Navbar.Brand>
            <Link to={ROUTES.HOME}>
              <Image src={logo} className="logo" />
              <Image src={logo_portal} className="logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="main-navbar-nav" />
          <Navbar.Collapse id="main-navbar-nav">
            <Nav>
              {userIsAuthenticated && (
                <LinkContainer to={JobManagementRoutes.getDefaultRoute(companyId)} exact>
                  <Nav.Item className="nav-link">{t("menu:menu_item_my_jobs")}</Nav.Item>
                </LinkContainer>
              )}
              {userIsAuthenticated && (
                <LinkContainer to={ROUTES.CANDIDATE_ALL} exact>
                  <Nav.Item className="nav-link">{t("menu:menu_item_candidates")}</Nav.Item>
                </LinkContainer>
              )}
              {canViewJobFairCompanies && (
                <NavDropdown title={t("menu:menu_item_admin")} id="admin-dropdown">
                  {canViewAdmin &&
                    <NavDropdown.Item>
                      <LinkContainer to={ROUTES.ADMIN_FAIR} exact>
                        <Nav.Item className="nav-link">{t("menu:menu_item_fair")}</Nav.Item>
                      </LinkContainer>
                    </NavDropdown.Item>
                  }
                  <NavDropdown.Item>
                    <LinkContainer to={ROUTES.ADMIN_COMPANIES} exact>
                      <Nav.Item className="nav-link">{t("menu:menu_item_companies")}</Nav.Item>
                    </LinkContainer>
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              {userIsAuthenticated && (
                <NavDropdown title={t("menu:menu_item_account")} id="identity-dropdown">
                  <NavDropdown.Item>
                    <LinkContainer to={ROUTES.SIGN_OUT} exact>
                      <Nav.Item>{t("menu:menu_item_sign_out")}</Nav.Item>
                    </LinkContainer>
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              {!userIsAuthenticated && (
                <LinkContainer to={ROUTES.SIGN_IN} exact>
                  <Nav.Item className="nav-link">{t("menu:menu_item_sign_in")}</Nav.Item>
                </LinkContainer>
              )}
              {!userIsAuthenticated && (
                <LinkContainer to={ROUTES.SIGN_UP} exact>
                  <Nav.Item className="nav-link">{t("menu:menu_item_sign_up")}</Nav.Item>
                </LinkContainer>
              )}
              {/* No subitems to display in language menu, only a click to switch to the other language */}
              {languageMenuStructure && languageMenuStructure.items.length === 0 && (
                <Nav.Item className="nav-link"
                          onClick={(event) => this.onClickLanguage(event, languageMenuStructure.title.code)}>{languageMenuStructure.title.name}</Nav.Item>
              )}
              {/* Subitems to display in language menu, display current language as dropdown title and then other languages */}
              {languageMenuStructure && languageMenuStructure.items.length > 0 && (
                <NavDropdown title={languageMenuStructure.title.name} id="language-dropdown">
                  {languageMenuStructure.items.map(language =>
                    <NavDropdown.Item key={language.code}>
                      <Nav.Item className="nav-link"
                                onClick={(event) => this.onClickLanguage(event, language.code)}>{language.name}</Nav.Item>
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default withTranslation()(withRouter(withAuthUser(Menu)));

