import React from "react";
import LoadingSpinner from "../loadingspinner/LoadingSpinner";
import FirebaseUserContext from "./FirebaseUserContext";
import Page from "../page/Page";
import withFirebase from "../firebase/withFirebase";
import DisconnectedModal from "./DisconnectedModal";

/**
 * Context provider that can be consumed by withFirebaseUser to get current Firebase user.
 *
 * Most of the time, use withAuthUser instead, which returns a more complete object representing the current user
 * (such as the user profile as well as the Firebase object for the user). The Firebase user contains its email,
 * its token ID (to prove that the user is authenticated) and other data managed by Firebase.
 *
 * The Firebase user provider (FirebaseUserProvider) and complete user provider (AuthUserProvider) are separate
 * because the FirebaseUserProvider component encloses the AuthenticatedApolloProvider (which needs to send the user
 * token ID to GraphQL), which encloses the AuthUserProvider (which needs an Apollo provider to fetch the user profile).
 * When the Firebase user changes, this provider re-renders and updates the Apollo provider with a new token and then
 * the AuthUser provider re-fetch the new user profile.
 *
 * Current Firebase user is an object with the following properties:
 *
 * <ul>
 *   <li>firebase: the Firebase user object</li>
 *   <li>enableFirebaseListener: listen to the onAuthStateChanged Firebase event. Required only because we don't
 *                               want to trigger it right after a user is created in Firebase but has not yet
 *                               been created in the database of users</li>
 *   <li>disableFirebaseListener: do not listen to the onAuthStateChanged Firebase event. Required for the same
 *                                reason as enableFirebaseListener.</li>
 *   <li>setFirebaseUser: callback to call when signing up to signal a change in the current Firebase user.
 *                        Required only because we don't want to trigger the onAuthStateChanged Firebase event
 *                        right after a user is created in Firebase but has not yet been created in the database of users.
 * </ul>
 *
 * This component listens to Firebase events and sets/clears the user in the context following these events.
 *
 * @param Component
 * @returns {function(*): *}
 */
class FirebaseUserProvider extends React.Component {
  state = {
    firebaseUser: null,
    showDisconnected: false,
    firstRender: true,
  };

  componentDidMount() {
    // Sets the value of a class property to true; no need to put it in state and trigger a rendering
    // Listen to change in Firebase user (namely sign ins and sign outs)
    this.enableFirebaseListener();

    // Fire the onAuthStateChanged event only if we have not disabled it
    this.listener = this.props.firebase.auth.onAuthStateChanged(firebaseUser => {
      if (this.enableListener) {
        this.setState({
          firebaseUser: firebaseUser,
          firstRender: false,
        });
      }
    });

    // Contact the Firebase server periodically to check if the user has not been disabled or deleted.
    // Firebase ID tokens have a time-to-live of one hour.
    const refreshDelay = process.env.REACT_APP_AUTH_REFRESH_DELAY;
    if (refreshDelay > 0) {
      this.scheduledTokenCheck = setInterval(() => this.getIdToken(true), process.env.REACT_APP_AUTH_REFRESH_DELAY);
    }
  }

  componentWillUnmount() {
    // Remove listener completely
    this.listener();
    // Stop checking if user is valid
    clearInterval(this.scheduledTokenCheck);
  }

  /**
   * Get the Firebase user token ID. If forceRefresh is false, the Firebase server won't be called unless the current
   * token has expired (Firebase tokens expire after one hour). If forceRefresh is true, the Firebase server will
   * be called.
   *
   * When the server is called and we learn that the user's credentials have been revoked, display a modal to explain
   * the situation. Also, the onAuthStateChanged event will be fired automatically and the page will re-render as
   * a non-authenticated user.
   *
   * @param forceRefresh True to force a Firebase server request even if local token is not expired
   * @returns {Promise<string>} Id token
   */
  getIdToken = (forceRefresh = false) => {
    // Check only if user is authenticated
    if (this.state.firebaseUser) {
      return this.state.firebaseUser.getIdToken(forceRefresh)
        .then((idToken) => {
          // Token is valid, return it
          return idToken;
        })
        .catch((err) => {
          // Token is invalid. User must be logged out.
          // onAuthStateChanged will fire automatically but set state.firebaseUser to null right now because
          // it might take some time before the event is called and this can produce an infinite loop (the state change
          // triggers a re-render of this component, which triggers new Apollo requests, which calls this method to
          // get the Id token if user is not null, which fails and brings us back here.
          // The state change will also redraw the page as an non-authenticated user. It will also display a modal
          // on top of whatever content to inform the user that he has been logged out.
          this.setState({
            firebaseUser: null,
            showDisconnected: true
          });
          return Promise.reject(err);
        });
    } else {
      // User is not logged in. Return an empty token.
      return Promise.resolve("");
    }
  };

  /**
   * When the onAuthStateChanged Firebase event is fired, do nothing
   */
  disableFirebaseListener = () => {
    this.enableListener = false;
  };

  /**
   * When the onAuthStateChanged Firebase event is fired, do something
   */
  enableFirebaseListener = () => {
    this.enableListener = true;
  };

  /**
   * This is a hack. Do not use when signing in nor signing out. Instead, rely on the onAuthStateChanged event to be fired
   * and to trigger the state change (and component re-render).
   * This method should be used only on sign up, after we re-instate enableFirebaseListener, to force a reload of the component
   * with the newly created (and signed in, because a Firebase user creation automatically performs a sign in) user.
   * @param firebaseUser
   */
  setFirebaseUser = (firebaseUser) => {
    this.setState({
      firebaseUser: firebaseUser,
    })
  };

  hideDisconnectedModal = () => {
    this.setState({showDisconnected: false});
  };

  render() {

    // Build the complete user object to return
    const enhancedFirebaseUser = {
      enableFirebaseListener: this.enableFirebaseListener,
      disableFirebaseListener: this.disableFirebaseListener,
      setFirebaseUser: this.setFirebaseUser,
      getFirebaseUserIdToken: this.getIdToken,
      firebase: this.state.firebaseUser,
    };

    // Build the contents to display
    let contents = null;

    if (this.state.firstRender) {
      // Avoid redirecting to Sign Up or Sign In when refreshing a private page
      // (because current user will be null for a small amount of time before
      // this.props.firebase.auth.onAuthStateChanged event is fired after Firebase initialization
      // and this will cause withAuthentication to forbid access to the private page)
      contents = (
        <Page>
          <LoadingSpinner/>
        </Page>
      );
    } else {
      // Display children. If the re-render is caused by the disconnection of the user, display an information modal too.
      // The user will know the exact cause of disconnection when logging in again
      contents = (
        <React.Fragment>
          <DisconnectedModal show={this.state.showDisconnected} handleClose={this.hideDisconnectedModal}/>
          {this.props.children}
        </React.Fragment>
      );
    }

    // Return a user provider
    return (
      <FirebaseUserContext.Provider value={enhancedFirebaseUser}>
        {contents}
      </FirebaseUserContext.Provider>
    );
  }
}

export default withFirebase(FirebaseUserProvider);
