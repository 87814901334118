import React from "react";
import RedirectToReferrer from "./RedirectToReferrer";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import StringUtils from "../../utils/StringUtils";
import FormText from "react-bootstrap/FormText";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import withFirebase from "../firebase/withFirebase";
import withAuthUser from "../session/withAuthUser";
import {withTranslation} from "react-i18next";
import "./SignInForm.scss";
import SpinnerButton from "../loadingspinner/SpinnerButton";
import Firebase from "../firebase/Firebase";

class SignInForm extends React.Component {
  INITIAL_STATE = {
    email: "",
    password: "",
    error: null,
    loading: false
  };

  constructor(props) {
    super(props);

    this.state = { ...this.INITIAL_STATE };
  }

  onSubmit = event => {
    event.preventDefault();

    const t = this.props.t;
    const { email, password } = this.state;

    // Hide previous errors, then perform sign in
    this.setState({ error: null, loading: true }, () => {
      this.props.firebase
        .doSignInWithEmailAndPassword(email.trim(), password)
        .catch(error => {
          const message = t(Firebase.getTranslationKey(error.code)) + " (code: " + error.code + ")";
          this.setState({ error: message, loading: false });
        });
    });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  canSubmit = () => {
    const t = this.props.t;

    let e = "";

    if (this.state.email === "") {
      e = t("session:sign_in_email_required");
    }
    if (this.state.password === "") {
      e = t("session:sign_in_password_required");
    }

    return e === "";
  };

  render() {
    const t = this.props.t;

    const { error } = this.state;

    // If already logged, or when rendering after sign in, do not display this page, redirect to HOME
    if (this.props.authUser.isAuthenticated) {
      return <RedirectToReferrer />;
    }

    return (
      <div className="SignInForm">
        <Form onSubmit={this.onSubmit}>
          <FormGroup>
            <FormControl
              name="email"
              placeholder={t("session:sign_in_email_placeholder")}
              value={StringUtils.nullToEmpty(this.state.email)}
              onChange={this.onChange}
              type="email"
              autoComplete="email"
            />
          </FormGroup>
          <FormGroup>
            <FormControl
              name="password"
              type="password"
              placeholder={t("session:sign_in_password_placeholder")}
              value={StringUtils.nullToEmpty(this.state.password)}
              onChange={this.onChange}
              autoComplete="current-password"
            />
          </FormGroup>
          <FormGroup className="last-button-group">
            <SpinnerButton
              loading={this.state.loading}
              type="submit"
              disabled={!this.canSubmit()}
              className="submit-button"
            >
              {t("session:sign_in_sign_in_button")}
            </SpinnerButton>
          </FormGroup>
          {!StringUtils.isNullOrEmpty(error) && (
            <FormText as="div" className="form-error">
              {error}
            </FormText>
          )}
        </Form>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  withRouter,
  withFirebase,
  withAuthUser
)(SignInForm);
