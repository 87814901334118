import React, { Component } from "react";
import * as ROUTES from "../../constants/routes";
import "./Footer.scss";
import {Link} from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Button } from "semantic-ui-react";
import Container from "react-bootstrap/Container";
import {withTranslation} from "react-i18next";

class Footer extends Component {
  render() {
    const t = this.props.t;

    return (
      <div className="Footer bg-light">
        <Container>
          <Row className="justify-content-sm-between">
            <Col>
              {/*Empty column to center second column and push last one to the far right*/}
            </Col>
            <Col xs="auto" className="page-col">
              <a href={ROUTES.MAW_MAIN_SITE}>{t("common:footer_main_site")}</a>
              {/*<Link to={ROUTES.PRIVACY}>{t("common:footer_privacy")}</Link>*/}
              <Link to={ROUTES.TERMS}>{t("common:footer_terms")}</Link>
              <Link to={ROUTES.CAREERS}>{t("common:footer_careers")}</Link>
              <Link to={ROUTES.CONTACT}>{t("common:footer_contact")}</Link>
            </Col>
            <Col className="social">
              <span>
                <a
                  href="https://www.facebook.com/Mouseatwork"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button circular color="facebook" icon="facebook" size="tiny"/>
                </a>
              </span>
              <span>
                <a
                  href="https://www.linkedin.com/company/mouse-at-work"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button circular color="linkedin" icon="linkedin" size="tiny"/>
                </a>
              </span>
            </Col>
          </Row>
          <Row>
            <Col className="copyright">
              <span>{t("common:footer_copyright")}</span>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(Footer);
