import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";

class QueryResumesForJob extends React.Component {

  static GET_RESUMES_FOR_JOB = gql`
    query bslJobFairGetBestMatchedResumesForJobBatched($jobId: String!) {
      bslJobFairGetBestMatchedResumesForJobBatched(jobId: $jobId) {
        _id
        matchScore
        resume {
          _id
          firstName
          lastName
          updated
          resumeFile {
            filename
          }
          technicalSkills {
            skills {
              _id
              name
            }
          }
          softSkills {
            _id
            name
          }
          email
          phone
          jobTitle
        },
        jobFair {
          title
          city
        }
      }
    }
  `;

  render() {
    // Do not make a call if we have nothing to analyze
    if (!this.props.job)
      return this.props.onLoaded([]);

    const queryVariables = {
      jobId: this.props.job._id
    };

    return (
      <Query
        query={QueryResumesForJob.GET_RESUMES_FOR_JOB}
        variables={queryVariables}
        notifyOnNetworkStatusChange
      >
        {result => {
          // loading is true when loading for the first time and networkStatus is 4 when refetching
          if (result.loading || result.networkStatus === 4) return this.props.onLoading();
          if (result.error) return this.props.onError();

          return this.props.onLoaded(result.data["bslJobFairGetBestMatchedResumesForJobBatched"], result.refetch);
        }}
      </Query>
    );
  }
}

export default QueryResumesForJob;
