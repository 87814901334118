import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";

class QueryJobFairById extends React.Component {
  static GET_JOB_FAIR = gql`
    query bslJobFairById($_id: String!) {
      bslJobFairById(_id: $_id) {
        _id
        organizer
        title
        city
        state
        country
        url
        email
        phone
        description
        dateBegin
        dateEnd
        booths {
          company {
            _id
            email
            name
          }
          number
        }
      }
    }
  `;

  render() {
    return (
      <Query
        query={QueryJobFairById.GET_JOB_FAIR}
        variables={{ _id: this.props.id }}
      >
        {result => {
          if (result.loading) return this.props.onLoading();
          if (result.error) return this.props.onError();
          return this.props.onLoaded(result.data["bslJobFairById"], result.refetch);
        }}
      </Query>
    );
  }
}

export default QueryJobFairById;
