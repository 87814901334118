import React from "react";
import Page from "../page/Page";

class CareersPage extends React.Component {
  render() {
    return (
      <Page>
        <h1>Carrières</h1>
        <p>
          Nous sommes désolés, nous n'avons pas de poste ouvert en ce moment.
        </p>
        <p>
          Cependant, nous sommes toujours heureux de rencontrer des professionnels énergiques et talentueux.
        </p>
        <p>
          Si vous êtes intéressés par de futures opportunités, envoyez-nous votre CV à
          info@mouseatwork.com.
        </p>
      </Page>
    );
  }
}

export default CareersPage;
