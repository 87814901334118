import React from "react";
import {withTranslation} from "react-i18next";

class LoadingDataError extends React.Component {
  render() {
    const t = this.props.t;
    return <div>{t("common:loading_data_error")}</div>;
  }
}

export default withTranslation()(LoadingDataError);
