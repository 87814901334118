import React from "react";
import withListHandlers from "../common/withListHandlers";
import FormGroup from "react-bootstrap/FormGroup";
import Button from "react-bootstrap/Button";
import BoothListItem from "./BoothListItem";
import TypeUtils from "../../utils/TypeUtils";

/**
 * List of booths in a job fair.
 * As a list, it inherits some properties from withListHandlers.
 */
class BoothList extends React.Component {

  /**
   * Add an empty booth to the list
   * @param event
   */
  onClickAdd = event => {
    event.preventDefault();
    this.props.addItem({"_id": "new-" + Date.now()});
  };

  /**
   * Returns a list of companies without those already assigned to a booth
   * @param companies All companies available
   * @param booths Assigned booths
   * @return {*} List of companies not assigned to a booth
   */
  static removeSelectedCompanies(companies, booths) {
    const selectedCompanies = TypeUtils.ensureArray(booths).map(booth => booth.companyId);
    return TypeUtils.ensureArray(companies).filter(company => !selectedCompanies.includes(company._id));
  };

  static addSelectedCompany(allCompanies, unselectedCompanies, selectedCompanyId) {
    // If current booth does not have a company yet, return all unselected companies
    if (!selectedCompanyId)
      return unselectedCompanies;

    // Find complete company info for selected company ID
    const selectedCompany = allCompanies.find(company => company._id === selectedCompanyId);

    // If current company cannot be found, return all unselected companies
    if (selectedCompany === undefined)
      return unselectedCompanies;

    // Return unselected companies prepended with current selected company info
    return [selectedCompany, ...unselectedCompanies];
  }

  render() {
    // Build the list of companies that were not selected yet
    // Every dropdown will display the list of unselected companies + its current company (so that it is selected by default)
    const unselectedCompanies = BoothList.removeSelectedCompanies(this.props.companies, this.props.items);

    return (
      <div className="spaced-children">
        {Array.isArray(this.props.items) && this.props.items.map(item =>
          <BoothListItem
            key={item._id}
            updateItem={(value) => this.props.updateItem(item._id, value)}
            deleteItem={() => this.props.deleteItem(item._id)}
            namePrefix={`${this.props.namePrefix}${item._id}-`}
            item={item}
            companies={BoothList.addSelectedCompany(this.props.companies, unselectedCompanies, item.companyId)}
          />
        )}
        <FormGroup className="add-link-group">
          <Button variant="link" className="action-link" onClick={this.onClickAdd}>
            Add booth
          </Button>
        </FormGroup>
      </div>
    );
  }
}

export default withListHandlers(BoothList);
