/**
 * List of supported languages. Code is the language code (eg. 'fr', 'fr-CA') and name is how the language is displayed
 * to the user in the app Menu.
 *
 * Translations must be provided in public/locales/{code}/{namespace}.json, where {code} must match one of the codes
 * defined here and where {namespace} is used when requesting a translation with t(namespace:key).
 */

// Use pre-ES6 syntax because translation analysis script in /scripts will complain it doesn't understand "export" instructions
module.exports = {
  'fr' : 'FR'
};
