import React from "react";
import {withTranslation} from "react-i18next";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import SpinnerButton from "../loadingspinner/SpinnerButton";
import gql from "graphql-tag";
import {Mutation} from "react-apollo";
import QueryCurrentJobsForCompany from "./QueryCurrentJobsForCompany";
import SuccessModal from "../common/SuccessModal";
import {withRouter} from "react-router-dom";
import ErrorModal from "../common/ErrorModal";
import JobManagementRoutes from "./JobManagementRoutes";

class AddJobTextAreaForm extends React.Component {

  static ADD_JOB_OPENING_FROM_TEXT = gql`
    mutation companyAddJobFromText($companyId: String!, $text: String) {
      companyAddJobFromText(companyId: $companyId, text: $text) {
        _id
        title
      }
    }
  `;

  static FULL_TEXT_MAX_LENGTH = 10000;

  state = {
    jobId: null,
    fullText: "",
    showSuccess: false,
    error: null
  };

  hideSuccess = () => {
    this.setState({ showSuccess: false }, () => {
      this.props.history.push(JobManagementRoutes.getPreviewRoute(this.props.match, this.state.jobId));
    });
  };

  hideError = () => {
    this.setState({ error: null });
  };

  hideNoTitleWarning = () => {
    this.setState({ showNoTitleWarning: false }, () => {
      this.props.history.push(JobManagementRoutes.getPreviewRoute(this.props.match, this.state.jobId));
    });
  };

  saveJobOpening = (mutationCallback) => {
    return mutationCallback({
      variables: {
        companyId: JobManagementRoutes.getCompanyIdFromRoute(this.props.match),
        text: this.state.fullText,
      }
    });
  };

  onChange = event => {

    const field = event.target.name;
    let value = event.target.value;

    // Full text textarea cannot exceed max length
    if (field === "fullText")
      value = value.substring(0, AddJobTextAreaForm.FULL_TEXT_MAX_LENGTH);

    this.setState({ [field]: value });
  };

  canSubmit = () => {
    return this.state.fullText.trim().length > 0;
  };

  onSubmit = (event, mutationCallback) => {
    const t = this.props.t;

    event.preventDefault();

    // Hide previous errors, then perform update
    this.setState({ error: null }, () => {
      this.saveJobOpening(mutationCallback)
        .then((result) => {
          const newJob = result.data["companyAddJobFromText"];

          if (newJob) {
            const jobHasNoTitle = newJob.title === "";

            this.setState({
              showNoTitleWarning: jobHasNoTitle,
              showSuccess: !jobHasNoTitle,
              jobId: newJob._id,
            });
          } else {
            return Promise.reject();
          }
        })
        .catch((error) => {
          let errorMsg = t("jobmanagement:add_job_cannot_analyze");
          if (error.message) {
            errorMsg = errorMsg + " " + t("jobmanagement:server_error", {serverError: error.message});
          }
          this.setState({error: errorMsg});
        });
    });
  };

  render () {
    const t = this.props.t;
    const refetchQueries = [
      {
        query: QueryCurrentJobsForCompany.GET_JOBS_FOR_COMPANY,
        variables: {
          _id: JobManagementRoutes.getCompanyIdFromRoute(this.props.match)
        }
      }
    ];

    return (
      <Mutation mutation={AddJobTextAreaForm.ADD_JOB_OPENING_FROM_TEXT} refetchQueries={refetchQueries}>
        {(mutationCallback, { loading }) => (
          <Form
            onSubmit={event => {
              this.onSubmit(event, mutationCallback);
            }}
          >
            {/* The modal is visible only when form has been submitted successfully */}
            <SuccessModal show={this.state.showSuccess} handleClose={this.hideSuccess}>
              <p>{t("jobmanagement:add_job_success_msg")}</p>
            </SuccessModal>
            {/* The modal is visible only when new job has no title */}
            <ErrorModal show={this.state.showNoTitleWarning} handleClose={this.hideNoTitleWarning}>
              <p>{t("jobmanagement:add_job_no_title_msg_1")}</p>
              <p>{t("jobmanagement:add_job_no_title_msg_2")}</p>
            </ErrorModal>
            {/* The modal is visible only when there are errors after form has been submitted */}
            <ErrorModal show={this.state.error !== null} handleClose={this.hideError}>
              <p>{this.state.error}</p>
            </ErrorModal>
            <FormGroup>
              <Form.Control
                name="fullText"
                as="textarea"
                rows="10"
                value={this.state.fullText}
                onChange={this.onChange}
                placeholder={t("jobmanagement:add_job_text_area_placeholder")}
              />
            </FormGroup>
            <FormGroup className="button-row">
              <SpinnerButton
                disabled={!this.canSubmit()}
                type="submit"
                loading={loading}
              >
                {t("jobmanagement:add_button")}
              </SpinnerButton>
            </FormGroup>
          </Form>
        )}
      </Mutation>
    );
  }
}

export default withTranslation()(withRouter(AddJobTextAreaForm));
