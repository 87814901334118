import React from "react";
import TypeUtils from "../../utils/TypeUtils";
import CandidateList from "./CandidateList";
import QueryResumesForJob from "./QueryResumesForJob";
import {withTranslation} from "react-i18next";

class StarsTab extends React.Component {

  // TODO: Fix this bug. Candidates are filtered, changing favorite property of one will overwrite all external candidates with filtered list.
  setExternalCandidates = candidates => {
    this.props.updateOpening(TypeUtils.shallowCopyObjectSetProp(this.props.opening, "externalCandidates", candidates));
  };

  render() {
    const t = this.props.t;

    if (!this.props.opening) {
      return (
        <div className="StarsTab">
          {t("jobmanagement:stars_no_opening")}
        </div>
      )
    }

    // TODO: put this back
    //const starCandidates = TypeUtils.ensureArray(this.props.opening.externalCandidates).filter(item => item.favorite);

    return null;

    /*
    return (
      <div className="StarsTab">
        <CandidateList
          items={starCandidates}
          setItems={this.setExternalCandidates}
          opening={this.props.opening}
        />
      </div>
    );
     */
  }
}

export default withTranslation()(StarsTab);
