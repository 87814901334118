import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";

class QueryJobFairs extends React.Component {
  static GET_JOB_FAIRS = gql`
    query BslJobFair {
      bslJobFair {
        _id
        title
        city
        dateBegin
        dateEnd
      }
    }
  `;

  render() {
    return (
      <Query
        query={QueryJobFairs.GET_JOB_FAIRS}
      >
        {result => {
          if (result.loading) return this.props.onLoading();
          if (result.error) return this.props.onError();
          return this.props.onLoaded(result.data.bslJobFair, result.refetch);
        }}
      </Query>
    );
  }
}

export default QueryJobFairs;
