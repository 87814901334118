import React from "react";
import gql from "graphql-tag";
import StringUtils from "../../utils/StringUtils";
import {Mutation} from "react-apollo";
import Form from "react-bootstrap/Form";
import SuccessModal from "../common/SuccessModal";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import FormControl from "react-bootstrap/FormControl";
import SpinnerButton from "../loadingspinner/SpinnerButton";
import FormText from "react-bootstrap/FormText";
import {withTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import {LinkContainer} from "react-router-bootstrap";
import * as ROUTES from "../../constants/routes";
import QueryJobFairs from "../jobmanagement/QueryJobFairs";
import {withRouter} from "react-router-dom";

class AdminFairUpdateForm extends React.Component {

  static CREATE_JOB_FAIR = gql`
      mutation CreateJobFair(
          $organizer: String!,
          $title: String!,
          $city: String!,
          $state: String,
          $country: String,
          $url: String,
          $email: String,
          $phone: String,
          $description: String,
          $dateBegin: Date,
          $dateEnd: Date,
          $resumes: [BslJobFairResumeInput],
          $booths: [BoothInput]) {
          bslJobFairAdd(
              organizer: $organizer,
              title: $title,
              city: $city,
              state: $state,
              country: $country,
              url: $url,
              email: $email,
              phone: $phone,
              description: $description,
              dateBegin: $dateBegin,
              dateEnd: $dateEnd,
              resumes: $resumes,
              booths: $booths
          ) {
              _id
          }
      }
  `;

  static UPDATE_JOB_FAIR = gql`
      mutation UpdateJobFair(
          $_id: String!,
          $organizer: String!,
          $title: String!,
          $city: String!,
          $state: String,
          $country: String,
          $url: String,
          $email: String,
          $phone: String,
          $description: String,
          $dateBegin: Date,
          $dateEnd: Date) {
          bslJobFairUpdate(
              _id: $_id,
              organizer: $organizer,
              title: $title,
              city: $city,
              state: $state,
              country: $country,
              url: $url,
              email: $email,
              phone: $phone,
              description: $description,
              dateBegin: $dateBegin,
              dateEnd: $dateEnd,
          ) {
              _id
          }
      }
  `;

  state = {
    _id: this.props.fair ? this.props.fair._id : null,
    organizer: this.props.fair ? this.props.fair.organizer : "",
    title: this.props.fair ? this.props.fair.title : "",
    city: this.props.fair ? this.props.fair.city : "",
    state: this.props.fair ? this.props.fair.state : "",
    country: this.props.fair ? this.props.fair.country : "",
    url: this.props.fair ? this.props.fair.url : "",
    email: this.props.fair ? this.props.fair.email : "",
    phone: this.props.fair ? this.props.fair.phone : "",
    description: this.props.fair ? this.props.fair.description : "",
    dateBegin: this.props.fair ? this.props.fair.dateBegin : "",
    dateEnd: this.props.fair ? this.props.fair.dateEnd : "",
    showResult: false,
    error: null
  };

  isCreating = () => {
    return this.state._id === null;
  };

  hideResult = () => {
    this.setState({showResult: false});
    this.props.history.push(ROUTES.ADMIN_FAIR);
  };

  saveFair = async (mutationCallback) => {
    const variables = this.isCreating() ? {
      // Creation
      organizer: this.state.organizer,
      title: this.state.title,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      url: this.state.url,
      email: this.state.email,
      phone: this.state.phone,
      description: this.state.description,
      dateBegin: StringUtils.isNullOrEmpty(this.state.dateBegin) ? null : this.state.dateBegin,
      dateEnd: StringUtils.isNullOrEmpty(this.state.dateEnd) ? null : this.state.dateEnd,
      resumes: [],
      booths: []
    } : {
      // Update
      _id: this.state._id,
      organizer: this.state.organizer,
      title: this.state.title,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      url: this.state.url,
      email: this.state.email,
      phone: this.state.phone,
      description: this.state.description,
      dateBegin: StringUtils.isNullOrEmpty(this.state.dateBegin) ? null : this.state.dateBegin,
      dateEnd: StringUtils.isNullOrEmpty(this.state.dateEnd) ? null : this.state.dateEnd,
    };

    return mutationCallback({variables});
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  canSubmit = () => {
    return (
      this.state.organizer.trim().length > 1 &&
      this.state.title.trim().length > 1 &&
      this.state.city.trim().length > 1
    );
  };

  onSubmit = (event, mutationCallback) => {
    event.preventDefault();

    // Hide previous errors, then perform update
    this.setState({error: null}, () => {
      this.saveFair(mutationCallback)
        .then(() => this.setState({showResult: true}))
        .catch((error) => this.setState({error: error}));
    });
  };

  render() {
    const t = this.props.t;
    const MANDATORY_TAG = " (*)";

    return (
      <div className="AdminFairUpdateForm">
        <Mutation
          mutation={this.isCreating() ? AdminFairUpdateForm.CREATE_JOB_FAIR : AdminFairUpdateForm.UPDATE_JOB_FAIR}
          refetchQueries={[
            {
              query: QueryJobFairs.GET_JOB_FAIRS,
            }
          ]}
        >
          {(mutationCallback, { loading }) => (
            <Form
              onSubmit={async event => {
                this.onSubmit(event, mutationCallback);
              }}
            >
              {/* The modal is visible only when form has been submitted successfully */}
              <SuccessModal show={this.state.showResult} handleClose={this.hideResult}>
                <p>{this.isCreating() ? t("admin:fair_created_successfully") : t("admin:fair_updated_successfully")}</p>
              </SuccessModal>
              <FormGroup>
                <FormLabel>{t("admin:fair_organizer_label") + MANDATORY_TAG}</FormLabel>
                <FormControl
                  name="organizer"
                  type="text"
                  value={StringUtils.nullToEmpty(this.state.organizer)}
                  onChange={this.onChange}
                  placeholder={t("admin:fair_organizer_label")}
                  autoComplete="organizer"
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t("admin:fair_title_label") + MANDATORY_TAG}</FormLabel>
                <FormControl
                  name="title"
                  type="text"
                  value={StringUtils.nullToEmpty(this.state.title)}
                  onChange={this.onChange}
                  placeholder={t("admin:fair_title_label")}
                  autoComplete="title"
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t("admin:fair_city_label") + MANDATORY_TAG}</FormLabel>
                <FormControl
                  name="city"
                  type="text"
                  placeholder={t("admin:fair_city_label")}
                  value={StringUtils.nullToEmpty(this.state.city)}
                  onChange={this.onChange}
                  autoComplete="city"
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t("admin:fair_state_label")}</FormLabel>
                <FormControl
                  name="state"
                  placeholder={t("admin:fair_state_label")}
                  value={StringUtils.nullToEmpty(this.state.state)}
                  onChange={this.onChange}
                  type="text"
                  autoComplete="state"
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t("admin:fair_country_label")}</FormLabel>
                <FormControl
                  name="country"
                  placeholder={t("admin:fair_country_label")}
                  value={StringUtils.nullToEmpty(this.state.country)}
                  onChange={this.onChange}
                  type="text"
                  autoComplete="country"
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t("admin:fair_url_label")}</FormLabel>
                <FormControl
                  name="url"
                  placeholder={t("admin:fair_url_label")}
                  value={StringUtils.nullToEmpty(this.state.url)}
                  onChange={this.onChange}
                  type="text"
                  autoComplete="url"
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t("admin:fair_email_label")}</FormLabel>
                <FormControl
                  name="email"
                  placeholder={t("admin:fair_email_label")}
                  value={StringUtils.nullToEmpty(this.state.email)}
                  onChange={this.onChange}
                  type="email"
                  autoComplete="email"
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t("admin:fair_phone_label")}</FormLabel>
                <FormControl
                  name="phone"
                  placeholder={t("admin:fair_phone_label")}
                  value={StringUtils.nullToEmpty(this.state.phone)}
                  onChange={this.onChange}
                  type="text"
                  autoComplete="phone"
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t("admin:fair_description_label")}</FormLabel>
                <FormControl
                  name="description"
                  placeholder={t("admin:fair_description_label")}
                  value={StringUtils.nullToEmpty(this.state.description)}
                  onChange={this.onChange}
                  type="text"
                  autoComplete="description"
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t("admin:fair_dateBegin_label")}</FormLabel>
                <FormControl
                  name="dateBegin"
                  placeholder={t("admin:fair_dateBegin_label")}
                  value={StringUtils.nullToEmpty(this.state.dateBegin)}
                  onChange={this.onChange}
                  type="text"
                  autoComplete="dateBegin"
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t("admin:fair_dateEnd_label")}</FormLabel>
                <FormControl
                  name="dateEnd"
                  placeholder={t("admin:fair_dateEnd_label")}
                  value={StringUtils.nullToEmpty(this.state.dateEnd)}
                  onChange={this.onChange}
                  type="text"
                  autoComplete="dateEnd"
                />
              </FormGroup>

              <FormGroup className="button-row">
                <SpinnerButton disabled={!this.canSubmit()} type="submit" loading={loading}>
                  {this.isCreating() ? t("admin:fair_create_button") : t("admin:fair_update_button")}
                </SpinnerButton>
                <LinkContainer to={ROUTES.ADMIN_FAIR}>
                  <Button variant="secondary">{t("admin:fair_cancel_button")}</Button>
                </LinkContainer>
              </FormGroup>
              {this.state.error && <FormText as="div" className="form-error">{this.state.error.message}</FormText>}
            </Form>
          )}
        </Mutation>
      </div>
    );
  }
}

export default withTranslation()(withRouter(AdminFairUpdateForm));
