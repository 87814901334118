import React from "react";
import withFirebaseUser from "./withFirebaseUser";
import {ApolloProvider} from "react-apollo";
import { createUploadLink } from 'apollo-upload-client';
import {setContext} from "apollo-link-context";
import {ApolloClient} from "apollo-client";
import {InMemoryCache} from "apollo-cache-inmemory";
import StringUtils from "../../utils/StringUtils";
import TypeUtils from "../../utils/TypeUtils";

/**
 * Provide an Apollo client with ApolloProvider that adds an "Authorization" header to every request.
 * The authorization token is the token ID of the user as given by Firebase.
 */
class AuthenticatedApolloProvider extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      apolloClient: this.createApolloClientForCurrentUser(),
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Create a new client with a new token ID (or none) if the firebase user changes (eg. signs in or signs out)
    if (this.props.firebaseUser !== prevProps.firebaseUser) {
      this.setState({
        apolloClient: this.createApolloClientForCurrentUser(),
      });
    }
  }

  // noinspection JSValidateJSDoc
  /**
   * Create an Apollo client. The URI of the GraphQL server is stored in REACT_APP_GRAPHQL_URI environment variable
   * (see method apolloHttpLink). An Authorization header is added to every request (see method apolloAuthLink).
   * @returns {ApolloClient<NormalizedCacheObject>} Apollo connection to a GraphQL server.
   */
  createApolloClientForCurrentUser = () => {
    return new ApolloClient({
      link: this.apolloAuthLink.concat(this.apolloHttpLink),
      cache: new InMemoryCache(),
      connectToDevTools: StringUtils.stringIsTrue(process.env.REACT_APP_APOLLO_CONNECT_TO_DEV_TOOLS)
    });
  };

  // noinspection JSValidateJSDoc
  /**
   * Use this when creating an Apollo client to specify the URI of the GraphQL server.
   * @type {ApolloLink} Apollo link
   */
  apolloHttpLink = createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
  });

  // noinspection JSValidateJSDoc
  /**
   * Use this when creating an Apollo client to add an Authorization header to every request.
   * @type {ApolloLink} Apollo link
   */
  apolloAuthLink = setContext((_, { headers }) => {

    if (this.props.firebaseUser && this.props.firebaseUser.firebase) {

      // Return the promise of headers with an authorization token
      // With getFirebaseUserIdToken(forceRefresh: false), the Firebase server is not called unless the current user ID
      // token has expired (after one hour). Therefore, deactivating or deleting a user in the Firebase admin console
      // won't invalidate the token here until the Firebase server is called after the local token one-hour expiration
      // delay. If the user is kicked out because its token has been revoked (user has been deactivated or deleted),
      // then the onAuthStateChanged event is fired and FirebaseUserProvider component will act accordingly.

      return this.props.firebaseUser.getFirebaseUserIdToken(false)
        .then(idToken => {
          // return the headers to the context so httpLink can read them
          return AuthenticatedApolloProvider.authorizationHeaders(headers, idToken);
        })
        .catch(err => {
          // Id token might have been revoked, abort request
          return Promise.reject(err);
        });
    } else {
      // Return the promise of headers with no authorization header because user is not logged in
      return Promise.resolve(AuthenticatedApolloProvider.authorizationHeaders(headers, null));
    }
  });

  /**
   * Augment request headers with an Authorization header and bearer token. If no token is provided, do not add the
   * Authorization header.
   * @param headers Request headers
   * @param token Authorization bearer token
   * @returns {{headers: *}} New headers
   */
  static authorizationHeaders = (headers, token) => {

    // Make a copy of the headers
    const newHeaders = TypeUtils.assign({}, headers);
    if (token)
      newHeaders.authorization = `Bearer ${token}`;
    return {
      headers: newHeaders
    };
  };

  render() {
    return (
      <ApolloProvider client={this.state.apolloClient}>
        {this.props.children}
      </ApolloProvider>
    );
  }
}

export default withFirebaseUser(AuthenticatedApolloProvider);
