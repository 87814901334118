import React from "react";
import Page from "../page/Page";

class ContactPage extends React.Component {
  render() {
    return (
      <Page>
        <h1>Contact</h1>
        <p>
          Nous serons heureux de répondre à toute question que vous pourriez avoir sur Mouse At Work.
          Contactez-nous et nous vous répondrons aussitôt que possible.
        </p>
        <h2>Email</h2>
        <p>
          info@mouseatwork.com
        </p>
        <h2>Téléphone</h2>
        <p>
          (+1) 514-993-5657
        </p>
        <h2>Adresse</h2>
        <p>
          7447 av. De Gaspé, Montréal (Québec), H2R 1Z9, Canada
        </p>
      </Page>
    );
  }
}

export default ContactPage;
