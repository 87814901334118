import React from "react";

/**
 * Use this class like this <ScrollToTopOnMount/> to trigger a scroll to top when the component mounts.
 * This ensures that when a user scrolls a page and then click a link to another page, the new page does not
 * keep the previous page scroll position. Instead, the new page is scrolled to the top.
 *
 * When the user hits the Previous or Next button of his browser, some browsers remember what was the scroll
 * position when the user left the page.
 *
 * This class comes from:
 * https://reacttraining.com/react-router/web/guides/scroll-restoration
 */
class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

export default ScrollToTopOnMount;
