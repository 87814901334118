import app from "firebase/app";
require("firebase/auth");

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID, // Project ID is not required, API key implies it
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
};

class Firebase {
  constructor() {
    try {
      // Initialize app only if none are already (causes an "already exists" error when hot-reloading)
      if (app.apps.length === 0) {
        app.initializeApp(config);
        this.auth = app.auth();
      }
      else {
        this.auth = app.auth();
      }
    } catch (err) {
      this.lastError = err;
    }
  }

  /**
   * Sets the language used by Firebase to send emails and error messages
   * @param language Language code (supported codes: https://github.com/firebase/firebaseui-web/blob/master/LANGUAGES.md)
   */
  setLanguageCode = (language) => {
    this.auth.languageCode = language
  };

  /**
   * Create a user in the Firebase service and log the user in.
   * @param email
   * @param password
   * @returns {Promise<firebase.auth.UserCredential>}
   */
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  /**
   * Log the user in.
   * @param email
   * @param password
   * @returns {Promise<firebase.auth.UserCredential>}
   */
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email.trim(), password);

  /**
   * Log the user out.
   * @returns {Promise<void>}
   */
  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  /**
   * Update the current user password.
   * @param password
   * @returns {Promise<void>}
   */
  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  /**
   * Returns current Firebase user.
   * Do not use this to know if there is user currently logged because it will return null for a short time
   * after firebase app is initialized; use it only after onAuthStatusChanged event is fired
   * @returns {firebase.User}
   */
  getCurrentUser = () => this.auth.currentUser;

  /**
   * Returns a translation key for a given Firebase error code. Error codes are listed in firebase-js-sdk project
   * on GitHub, file packages/auth/src/error_auth.js.
   * This method is private because it is not prefixed by a i18next key prefix.
   * @param code Firebase error code
   * @return {string} Translation key
   * @private
   */
  static _getRawTranslationKey = (code) => {
    switch(code) {
      case "auth/email-already-in-use": return "auth_email_already_in_use_error";
      case "auth/invalid-email" : return "auth_invalid_email_error";
      case "auth/operation-not-allowed": return "auth_operation_not_allowed_error";
      case "auth/too-many-requests": return "auth_too_many_requests_error";
      case "auth/user-disabled" : return "auth_user_disabled_error";
      case "auth/user-not-found" : return "auth_user_not_found_error";
      case "auth/weak-password" : return "auth_weak_password_error";
      case "auth/wrong-password": return "auth_wrong_password_error";
      default: return "generic_error";
    }
  };

  /**
   * Returns a translation key for a given Firebase error code (translation namespace prefix included).
   * @param code Firebase error code
   * @return {string} Translation key
   */
  static getTranslationKey = (code) => {
    return "firebase:" + Firebase._getRawTranslationKey(code);
  };
}

export default Firebase;
