import React from "react";
import "./PasswordForgetPage.scss";
import Page from "../page/Page";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import PasswordForgetForm from "./PasswordForgetForm";
import {withTranslation} from "react-i18next";
import Image from "react-bootstrap/Image";
import logo_portal from "../../img/bsl-cropped.png";

class PasswordForgetPage extends React.Component {
  render() {
    return (
      <Page>
        <div className="PasswordForgetPage spaced-children">
          <Row>
            <Col>{/*empty row for even spacing*/}</Col>
          </Row>
          <Row>
            <Col>
              <Image src={logo_portal} className="portal_logo" />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="6">
              <WithTranslationPasswordForgetBlock />
            </Col>
          </Row>
        </div>
      </Page>
    );
  }
}

class PasswordForgetBlock extends React.Component {
  render() {
    const t = this.props.t;

    return (
      <Card className="PasswordForgetBlock">
        <Card.Body>
          <Row>
            <Col>
              <h2>{t("settings:password_forget_title")}</h2>
              <p>{t("settings:password_forget_msg")}</p>
              <PasswordForgetForm />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

const WithTranslationPasswordForgetBlock = withTranslation()(PasswordForgetBlock);

export default PasswordForgetPage;
