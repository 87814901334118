import React from "react";
import "./ThankYouPage.scss";
import Page from "../page/Page";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo_portal from "../../img/bsl-cropped.png";
import Image from "react-bootstrap/Image";
import {withTranslation} from "react-i18next";

class ThankYouPage extends React.Component {
  render() {
    return (
      <Page>
        <div className="ThankYouPage spaced-children">
          <Row>
            <Col>{/*empty row for even spacing*/}</Col>
          </Row>
          <Row>
            <Col>
              <Image src={logo_portal} className="portal_logo" />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="6">
              <WithTranslationThankYouBlock />
            </Col>
          </Row>
        </div>
      </Page>
    );
  }
}

class ThankYouBlock extends React.Component {
  render() {
    const t = this.props.t;

    return (
      <div className="ThankYouBlock">
        <Row>
          <Col>
            <h4>{t("session:thankyou_msg_1")}</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>{t("session:thankyou_msg_2")}</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            {t("session:thankyou_msg_3")} <a href="http://www.mouseatwork.com">www.mouseatwork.com</a>
          </Col>
        </Row>
      </div>
    );
  }
}

const WithTranslationThankYouBlock = withTranslation()(ThankYouBlock);

export default ThankYouPage;
