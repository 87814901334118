import React from "react";
import FirebaseContext from "./FirebaseContext";

/**
 * Higher Order Component that fills the firebase prop of wrapped component with the current connection to Firebase.
 *
 * @returns {function(*): *}
 */
const withFirebase = Component => props => (
  <FirebaseContext.Consumer>
    {firebase => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

export default withFirebase;
