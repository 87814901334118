import React from "react";
import Button from "react-bootstrap/Button";

/**
 * Higher order component that takes a component as argument and returns another component.
 * This HOC is intended to wrap a component that is a Material UI icon. It makes the component clickable
 * by wrapping it with a link-type Bootstrap Button.
 * @param WrappedComponent
 * @returns {}
 */
function withButton(WrappedComponent) {

  /**
   * Enhanced component to return
   */
  return class extends React.Component {

    render() {
      // Render the wrapped component inside a Button component. All props are passed to the Button component.
      return (
        <Button type="button" variant="link" {...this.props}>
          <WrappedComponent/>
        </Button>
      );
    }
  };
}

export default withButton;
