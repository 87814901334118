import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import gql from "graphql-tag";
import {Mutation} from "@apollo/react-components";
import SpinnerButton from "../loadingspinner/SpinnerButton";
import QueryCurrentJobsForCompany from "./QueryCurrentJobsForCompany";
import {withRouter} from "react-router-dom";
import ErrorModal from "../common/ErrorModal";
import {withTranslation} from "react-i18next";
import JobPreviewForm from "./JobPreviewForm";
import JobManagementRoutes from "./JobManagementRoutes";

class PreviewTab extends React.Component {
  static DELETE_JOB_OPENING = gql`
    mutation companyDeleteJob($companyId: String!, $jobId: String!) {
        companyDeleteJob(companyId: $companyId, jobId: $jobId) {
        _id
      }
    }
  `;

  state = {
    error: null
  };

  clearError = () => {
    this.setState({ error: null });
  };

  onClickDelete = (event, deleteCallback) => {
    event.preventDefault();

    this.setState(
      {error: null},
      // Perform this callback when setState is done
      () => deleteCallback({
        variables: {
          companyId: JobManagementRoutes.getCompanyIdFromRoute(this.props.match),
          jobId: this.props.opening._id
        }
      }).catch((err) =>
        this.setState({
          error: this.props.t("jobmanagement:job_preview_deletion_error") + err.message
        })
      )
    );
  };

  onClickModify = (event) => {
    event.preventDefault();
    this.setState({modify: true});
  };

  exitModifyMode = () => {
    this.setState({modify: false});
  };

  render() {
    const t = this.props.t;

    if (!this.props.opening)
      return null;

    return (
      <div className="PreviewTab spaced-children">
        <Row>
          <Col>
            <JobPreviewForm job={this.props.opening} modify={this.state.modify} exitModifyMode={this.exitModifyMode}/>
          </Col>
        </Row>
        {!this.state.modify &&
          <Row>
            <Col className="button-row">
              <SpinnerButton onClick={this.onClickModify}>
                {t("jobmanagement:job_preview_modify_button")}
              </SpinnerButton>
              <Mutation
                mutation={PreviewTab.DELETE_JOB_OPENING}
                refetchQueries={[
                  {
                    query: QueryCurrentJobsForCompany.GET_JOBS_FOR_COMPANY,
                    variables: {
                      _id: JobManagementRoutes.getCompanyIdFromRoute(this.props.match)
                    }
                  }
                ]}
              >{(deleteCallback, {loading}) =>
                <React.Fragment>
                  {/* The modal is visible only when operation returned an error */}
                  <ErrorModal
                    show={this.state.error !== null}
                    handleClose={this.clearError}
                  >
                    <p>{this.state.error}</p>
                  </ErrorModal>
                  <SpinnerButton loading={loading} onClick={event => this.onClickDelete(event, deleteCallback)}>
                    {t("jobmanagement:job_preview_delete_button")}
                  </SpinnerButton>
                </React.Fragment>
              }
              </Mutation>
            </Col>
          </Row>
        }
      </div>
    );
  }
}

export default withTranslation()(withRouter(PreviewTab));
