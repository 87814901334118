import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./ExpandableSection.scss";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

class ExpandableSection extends React.Component {

  state = {
    visible: this.props.visible,
  };

  onClickShow = (event) => {
    event.preventDefault();
    this.setState(prevState => ({visible: !prevState.visible}));
  };

  render() {
    return(
      <div className="ExpandableSection">
        {this.props.header &&
        <Row className="header-row">
          <Col>
            <Button variant="link" onClick={this.onClickShow}>
              {this.state.visible ?
                <ArrowDropDownIcon/> :
                <ArrowRightIcon/>}
              {this.props.header}
            </Button>
          </Col>
        </Row>
        }
        {this.state.visible &&
        <Row>
          <Col className="expandable-content">
            {this.props.children}
          </Col>
        </Row>
        }
      </div>
    )
  }
}

export default ExpandableSection;
