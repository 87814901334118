import React from "react";
import LoadingSpinner from "../loadingspinner/LoadingSpinner";
import LoadingDataError from "../common/LoadingDataError";
import QueryResumesForJob from "./QueryResumesForJob";
import CandidateList from "./CandidateList";
import {withTranslation} from "react-i18next";

class CandidateListForJob extends React.Component {

  showLoading = () => {
    return <LoadingSpinner />;
  };

  showError = () => {
    return <LoadingDataError/>;
  };

  render() {
    const t = this.props.t;

    return (
      <QueryResumesForJob job={this.props.opening} onLoading={this.showLoading} onError={this.showError} onLoaded={(scoredResumes, refetch) => {
        if (!scoredResumes || scoredResumes.length === 0)
          return t("jobmanagement:candidate_list_none");

        return (
          <CandidateList
            items={scoredResumes}
            setItems={this.updateOpening}
            opening={this.props.opening}
            refresh={refetch}
          />
        );
      }}/>
    );
  }
}

export default withTranslation()(CandidateListForJob);
