import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import StringUtils from "../../utils/StringUtils";
import TypeUtils from "../../utils/TypeUtils";

class QueryCompanySummaries extends React.Component {
  static GET_COMPANY_SUMMARIES = gql`
    query companySummaries {
      companySummaries {
        _id
        name
        email
      }
    }
  `;

  render() {
    return (
      <Query query={QueryCompanySummaries.GET_COMPANY_SUMMARIES}>
        {result => {
          if (result.loading) return this.props.onLoading();
          if (result.error) return this.props.onError();

          // Sort summaries
          const summaries = TypeUtils.ensureArray(result.data["companySummaries"]);
          summaries.sort((a, b) => {
            // Sort by name
            const nameComparison = StringUtils.nullToEmpty(a.name).localeCompare(b.name);
            if (nameComparison !== 0)
              return nameComparison;

            // If same name, sort by email
            const emailComparison = StringUtils.nullToEmpty(a.email).localeCompare(b.email);
            if (emailComparison !== 0)
              return emailComparison;

            // If same email, sort by id
            return StringUtils.nullToEmpty(a._id).localeCompare(b._id);
          });

          return this.props.onLoaded(summaries, result.refetch);
        }}
      </Query>
    );
  }
}

export default QueryCompanySummaries;
