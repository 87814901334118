import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";

class QueryCurrentJobsForCompany extends React.Component {
  static GET_JOBS_FOR_COMPANY = gql`
    query CompanyById($_id: String!) {
      companyById(_id: $_id) {
        _id
        name
        currentJobOpenings {
          _id
          title
          fullText
          nlpRequiredTechnicalSkills
          nlpRequiredSoftSkills
        }
      }
    }
  `;

  render() {
    const queryVariables = {
      _id: this.props._id
    };

    return (
      <Query
        query={QueryCurrentJobsForCompany.GET_JOBS_FOR_COMPANY}
        variables={queryVariables}
      >
        {result => {
          if (result.loading) return this.props.onLoading();
          if (result.error) return this.props.onError();

          return this.props.onLoaded(result.data.companyById);
        }}
      </Query>
    );
  }
}

export default QueryCurrentJobsForCompany;
