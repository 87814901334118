import React from "react";
import Form from "react-bootstrap/Form";
import QueryCompanySummaries from "./QueryCompanySummaries";
import LoadingSpinner from "../loadingspinner/LoadingSpinner";
import LoadingDataError from "../common/LoadingDataError";
import gql from "graphql-tag";
import * as ROUTES from "../../constants/routes";
import StringUtils from "../../utils/StringUtils";
import TypeUtils from "../../utils/TypeUtils";
import {Mutation} from "react-apollo";
import QueryJobFairById from "./QueryJobFairById";
import {withRouter} from "react-router-dom";
import SuccessModal from "../common/SuccessModal";
import {withTranslation} from "react-i18next";
import FormGroup from "react-bootstrap/FormGroup";
import SpinnerButton from "../loadingspinner/SpinnerButton";
import {LinkContainer} from "react-router-bootstrap";
import Button from "react-bootstrap/Button";
import FormText from "react-bootstrap/FormText";
import BoothList from "./BoothList";

class AdminFairBoothForm extends React.Component {

  static UPDATE_BOOTHS = gql`
    mutation bslJobFairUpdateBooths($_id: String!, $booths: [BoothInput]!) {
      bslJobFairUpdateBooths(_id: $_id, booths: $booths) {
        _id
      }
    }
  `;

  state = {
    booths: [],
    showResult: false,
    error: null
  };

  componentDidMount() {
    this.setState({booths: AdminFairBoothForm.flattenBooths(this.props.fair.booths)})
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.fair._id !== prevProps.fair._id)
      this.setState({booths: AdminFairBoothForm.flattenBooths(this.props.fair.booths)});
  }

  showLoading = () => {
    return <LoadingSpinner/>;
  };

  showError = () => {
    return <LoadingDataError/>;
  };

  hideResult = () => {
    this.setState({showResult: false});
    this.props.history.push(ROUTES.ADMIN_FAIR);
  };

  /**
   * Transform every booth structure in an array to a flat booth structure, so that every property is at top level
   * and we can use withListHandlers to change a field (fields with "." in their name are not supported)
   */
  static flattenBooths = (booths) => {
    const flattenedBooths = TypeUtils.ensureArray(booths).map((booth, index) => ({
      companyId: booth.company ? booth.company._id : null,
      companyName: booth.company ? booth.company.name : null,
      companyEmail : booth.company ? booth.company.email : null,
      _id: index, // Required to use withListHandlers
      boothNumber: booth.number,
    }));

    // Sort booths according to booth number
    flattenedBooths.sort((a, b) => StringUtils.nullToEmpty(a.boothNumber).localeCompare(b.boothNumber));
    return flattenedBooths;
  };

  saveFair = async (mutationCallback) => {

    const boothsToSend = this.state.booths.map(booth => ({
      company: booth.companyId,
      number: booth.boothNumber,
    }));

    const variables = {
      _id: this.props.fair._id,
      booths: boothsToSend,
    };

    return mutationCallback({variables});
  };

  onSubmit = (event, mutationCallback) => {
    event.preventDefault();

    // Hide previous errors, then perform update
    this.setState({error: null}, () => {
      this.saveFair(mutationCallback)
        .then(() => this.setState({showResult: true}))
        .catch((error) => this.setState({error: error}));
    });
  };

  canSubmit = () => {
    // Cannot submit if one booth has no company
    return (this.state.booths.findIndex(item => StringUtils.isNullOrEmpty(item.companyId)) === -1);
  };

  setBooths = (booths) => {
    this.setState({booths});
  };

  render() {
    const t = this.props.t;

    return (
      <div className="AdminFairCompanyForm">
        <QueryCompanySummaries onLoading={this.showLoading} onError={this.showError} onLoaded={companies =>
          <Mutation
            mutation={AdminFairBoothForm.UPDATE_BOOTHS}
            refetchQueries={[
              {
                query: QueryJobFairById.GET_JOB_FAIR,
                variables: {_id: this.props.fair._id}
              }
            ]}
          >
            {(mutationCallback, { loading }) => (
              <Form onSubmit={event => this.onSubmit(event, mutationCallback)}>
                {/* The modal is visible only when form has been submitted successfully */}
                <SuccessModal show={this.state.showResult} handleClose={this.hideResult}>
                  <p>{t("admin:fair_updated_successfully")}</p>
                </SuccessModal>
                <FormGroup className="booth-list">
                  <BoothList
                    items={this.state.booths}
                    namePrefix={`booths-`}
                    setItems={this.setBooths}
                    companies={companies}
                  />
                </FormGroup>
                <FormGroup className="button-row">
                  <SpinnerButton disabled={!this.canSubmit()} type="submit" loading={loading}>
                    {t("admin:fair_update_button")}
                  </SpinnerButton>
                  <LinkContainer to={ROUTES.ADMIN_FAIR}>
                    <Button variant="secondary">{t("admin:fair_cancel_button")}</Button>
                  </LinkContainer>
                </FormGroup>
                {this.state.error && <FormText as="div" className="form-error">{this.state.error.message}</FormText>}
              </Form>
            )}
          </Mutation>
        }/>
      </div>
    );
  }
}

export default withTranslation()(withRouter(AdminFairBoothForm));
