import React from "react";
import Modal from "react-bootstrap/Modal";
import TermsPage from "../footer/TermsPage";
import Button from "react-bootstrap/Button";
import {withTranslation} from "react-i18next";

class Terms extends React.Component {
  render() {
    const t = this.props.t;

    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header closeButton>{t("session:terms_modal_title")}</Modal.Header>
        <Modal.Body>{TermsPage.termsText}</Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>{t("common:close_button")}</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(Terms);
