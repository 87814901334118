import React from "react";
import Analytics from "react-router-ga";

/**
 * Send Google Analytics page view stats about current location in a React Router context using react-router-ga.
 * Do not send stats if not in production.
 * A more customizable class (eg. to send current user) can be found here:
 * https://vanja.gavric.org/blog/integrate-google-analytics-with-react-router-v4/
 */
class AnalyticsWrapper extends React.Component {
  render () {
    if (process.env.REACT_APP_ANALYTICS_TRACKING_ID)
      return <Analytics id={process.env.REACT_APP_ANALYTICS_TRACKING_ID}>{this.props.children}</Analytics>;
    else
      return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

export default AnalyticsWrapper;
