import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import withAuthUser from "./withAuthUser";
import * as ROUTES from "../../constants/routes";
import {Redirect} from "react-router-dom";
import TypeUtils from "../../utils/TypeUtils";

/**
 * Higher Order Component to redirect to sign in page when user is not logged in.
 * A listener ensures that the condition is checked as soon as Firebase changes authUser, such as when
 * doCreateUserWithEmailAndPassword, doSignInWithEmailAndPassword and doSignOut are called.
 *
 * Typical usage:
 *
 * import withAuthentication from "../session/withAuthentication";
 * export default withAuthentication(Page);
 *
 * @returns {function(*): *}
 */
const withAuthentication = Component => {
  class WithAuthentication extends React.Component {

    render() {
      if (!this.props.authUser.isAuthenticated) {
        // Referrer is current location without its own referrer state, if any
        const referrer = TypeUtils.shallowCopyObject(this.props.location);
        if (referrer.state)
          delete referrer.state.referrer;
        return <Redirect to={{
          pathname: ROUTES.SIGN_UP,
          state: { referrer: referrer}
        }}
        />
      }

      return <Component {...this.props} />;
    }
  }

  return compose(
    withRouter,
    withAuthUser
  )(WithAuthentication);
};

export default withAuthentication;
