import Moment from "moment";

class DateFormatters {

  /**
   * Displays a timestamp in ISO style (YYYY-MM-DD), without the time. If timestamp has a timezone indication,
   * time is converted to local timezone.
   * @param timestamp
   * @return {string}
   */
  static formatDateNoTime(timestamp) {
    const moment = Moment(timestamp);
    return moment.format("YYYY-MM-DD");
  }

  /**
   * Displays a timestamp in ISO style (YYYY-MM-DD HH:mm). If timestamp has a timezone indication,
   * time is converted to local timezone.
   * @param timestamp
   * @return {string}
   */
  static formatDateWithTime(timestamp) {
    const moment = Moment(timestamp);
    return moment.format("YYYY-MM-DD HH:mm");
  }
}

export default DateFormatters;
