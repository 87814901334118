import React from "react";
import QueryAllResumes from "./QueryAllResumes";
import LoadingSpinner from "../loadingspinner/LoadingSpinner";
import LoadingDataError from "../common/LoadingDataError";
import CandidateListItem from "./CandidateListItem";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SpinnerButton from "../loadingspinner/SpinnerButton";
import {withTranslation} from "react-i18next";

class CandidateSearchResultList extends React.Component {

  showLoading = () => {
    return <LoadingSpinner />;
  };

  showError = () => {
    return <LoadingDataError/>;
  };

  render() {
    const t = this.props.t;

    return (
      <QueryAllResumes searchQuery={this.props.searchFilters}>
        {(status, error, data, loadMore) => {
          // Query encountered an error, display error message
          if (status === QueryAllResumes.STATUS.ERROR) return this.showError(error);

          // Query is loading for the first time, return a spinner
          if (status === QueryAllResumes.STATUS.LOADING) return this.showLoading();

          // Query is finished loading, or query is waiting for more results after "Load more" is clicked:
          // display results so far
          return (
            <div className="CandidateSearchResultList spaced-children">
              {data.nodeResults.map((item, index) => (
                <Row key={index}>
                  <Col>
                    <CandidateListItem
                      key={index}
                      item={item}
                      itemNumber={index+1}
                      namePrefix={`candidateListItem-${item._id}-`}
                    />
                  </Col>
                </Row>
              ))}
              {data.nodeResults.length === 0 && (
                <span>{t("jobmanagement:candidate_no_result")}</span>
              )}
              {data.hasNextPage && (
                <Row className="button-row">
                  <Col>
                    <SpinnerButton
                      loading={status === QueryAllResumes.STATUS.LOADING_MORE}
                      onClick={loadMore}
                    >
                      {t("jobmanagement:candidate_load_more")}
                    </SpinnerButton>
                  </Col>
                </Row>
              )}
            </div>
          );
        }}
      </QueryAllResumes>
    );
  }
}

export default withTranslation()(CandidateSearchResultList);
