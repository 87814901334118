import React from 'react';
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import "./SpinnerButton.scss";

class SpinnerButton extends React.Component {

  render() {
    const {loading, children, ...props} = this.props;

    return (
      <div className="SpinnerButton">
        <div className={"button-with-label " + (loading? "hidden" : "visible")}>
          <Button {...props} >{children}</Button>
        </div>
        <div className={"button-with-spinner " + (loading ? "visible" : "hidden")}>
          <Button {...props}>
            <Spinner as="span" animation="border" variant="light" size="sm" role="status"/>
          </Button>
        </div>
      </div>
    );

  }
}

export default SpinnerButton;
