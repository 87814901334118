import React from "react";
import PasswordChangeForm from "./PasswordChangeForm";
import PrivatePage from "../page/PrivatePage";
import {withTranslation} from "react-i18next";

class PasswordChangePage extends React.Component {
  render() {
    const t = this.props.t;

    return (
      <PrivatePage>
        <h1>{t("settings:password_change_title")}</h1>
        <PasswordChangeForm />
      </PrivatePage>
    );
  }
}

export default withTranslation()(PasswordChangePage);
