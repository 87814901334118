import React from "react";
import TypeUtils from "../../utils/TypeUtils";

/**
 * Higher order component that takes a component as argument and returns another component.
 * This HOC is intended to wrap a component that is a list of items. It provides the wrapped component with
 * handlers to manipulate items in the list.
 * The wrapped component must have a setItem property.
 * Items passed to list must have an _id property.
 * A copy of the item list is made whenever an item is modified because we assume the item list is a React component
 * property that must not be modified directly.
 * @param WrappedComponent
 * @returns {}
 */
function withListHandlers(WrappedComponent) {

  /**
   * Enhanced component to return
   */
  return class extends React.Component {

    /**
     * Copy the list and adds an item to it
     * @param item
     */
    addItem = item => {
      this.props.setItems(TypeUtils.shallowCopyArrayPush(this.props.items, item));
    };

    /**
     * Copy the list and delete the item with given id.
     * @param _id
     */
    deleteItem = _id => {
      this.props.setItems(TypeUtils.shallowCopyArrayFindAndRemove(this.props.items, item => item._id === _id));
    };

    /**
     * Copy the list and replace the item with given id.
     * @param key ID of item to replace
     * @param item New item
     */
    updateItem = (key, item) => {
      this.props.setItems(TypeUtils.shallowCopyArrayFindAndReplace(this.props.items, item, item => item._id === key));
    };

    render() {
      // Render the wrapped component and augment it with additional list handlers
      return <WrappedComponent
        {...this.props}
        addItem={this.addItem}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
      />;
    }
  };
}

export default withListHandlers;
