import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";

class QueryMaxUploadFileSize extends React.Component {

  static GET_MAX_UPLOAD_FILE_SIZE = gql`
    query companyJobMaxUploadFileSize {
      companyJobMaxUploadFileSize
    }
  `;

  render() {
    return (
      <Query query={QueryMaxUploadFileSize.GET_MAX_UPLOAD_FILE_SIZE}>
        {result => {
          if (result.loading) return this.props.onLoading();
          if (result.error) return this.props.onError();

          return this.props.onLoaded(result.data.companyJobMaxUploadFileSize);
        }}
      </Query>
    );
  }
}

export default QueryMaxUploadFileSize;
