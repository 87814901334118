import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import SuccessModal from "../common/SuccessModal";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import FormText from "react-bootstrap/FormText";
import withFirebase from "../firebase/withFirebase";
import {withTranslation} from "react-i18next";
import "./PasswordForgetForm.scss";

class PasswordForgetForm extends Component {

  static INITIAL_STATE = {
    email: "",
    error: null
  };

  constructor(props) {
    super(props);
    this.state = { ...PasswordForgetForm.INITIAL_STATE, showResult: false };
  }

  hideResult = () => {
    this.setState({ showResult: false });
  };

  onSubmit = event => {
    const { email } = this.state;
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...PasswordForgetForm.INITIAL_STATE, showResult: true });
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const t = this.props.t;

    const { email, showResult, error } = this.state;
    const isInvalid = email === "";
    return (
      <div className="PasswordForgetForm">
        <Form onSubmit={this.onSubmit}>
          {/* The modal is visible only when form has been submitted successfully */}
          <SuccessModal show={showResult} handleClose={this.hideResult}>
            <p>{t("settings:password_forget_success_msg")}</p>
          </SuccessModal>
          <FormGroup>
            <FormControl
              name="email"
              value={this.state.email}
              onChange={this.onChange}
              placeholder={t("settings:password_forget_email_placeholder")}
              type="email"
              autoComplete="email"
            />
          </FormGroup>
          <FormGroup className="last-button-group">
            <Button
              disabled={isInvalid}
              type="submit"
              className="submit-button"
            >
              {t("settings:password_forget_reset_button")}
            </Button>
          </FormGroup>
          {error && (
            <FormText as="div" className="form-error">
              {error.message}
            </FormText>
          )}
        </Form>
      </div>
    );
  }
}

export default withTranslation()(withFirebase(PasswordForgetForm));
