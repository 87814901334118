import React from "react";
import FirebaseUserContext from "./FirebaseUserContext";

/**
 * Higher Order Component that fills the firebaseUser prop of wrapped component with the current Firebase user.
 *
 * Most of the time, use withAuthUser instead, which returns a more complete object representing the current user
 * (such as the user profile as well as the Firebase object for the user).
 *
 * @returns {function(*): *}
 */
function withFirebaseUser(Component) {
  return function(props) {
    return (
      <FirebaseUserContext.Consumer>
        {firebaseUser => <Component firebaseUser={firebaseUser} {...props}/>}
      </FirebaseUserContext.Consumer>
    );
  }
}

export default withFirebaseUser;
