import React, {Component} from "react";
import Page from "../page/Page";
import {withTranslation} from "react-i18next";
import "./NotFoundPage.scss";

class NotFoundPage extends Component {
  render() {
    const t = this.props.t;
    return (
      <Page>
        <div className="NotFoundPage">
          {t('notfound:content')}
        </div>
      </Page>
    );
  }
}

export default withTranslation()(NotFoundPage);
