import React from "react";
import "./JobManagementPage.scss";
import PrivatePage from "../page/PrivatePage";
import CandidateListWithSearchPane from "./CandidateListWithSearchPane";

class CandidateAllPage extends React.Component {

  render() {
    return (
      <PrivatePage>
        <CandidateListWithSearchPane/>
      </PrivatePage>
    );
  }
}

export default CandidateAllPage;
