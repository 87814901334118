import React from "react";
import withListHandlers from "../common/withListHandlers";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CandidateListItem from "./CandidateListItem";
import RefreshIcon from "@material-ui/icons/Refresh";
import "./CandidateList.scss";
import Button from "react-bootstrap/Button";
import {withTranslation} from "react-i18next";

/**
 * List of candidates for a job opening.
 * As a list, it inherits some properties from withListHandlers.
 */
class CandidateList extends React.Component {

  onClickRefresh = (event) => {
    event.preventDefault();
    this.props.refresh();
  };

  render() {
    const t = this.props.t;

    return (
      <div className="CandidateList spaced-children">
        {this.props.refresh &&
        <Row>
          <Col className="refresh-col">
            <Button variant="link" onClick={this.onClickRefresh}><RefreshIcon className="button-icon"/>{t("jobmanagement:candidate_list_refresh_action")}</Button>
          </Col>
        </Row>
        }
        {Array.isArray(this.props.items) && this.props.items.map((item, index) => (
          <Row key={item._id}>
            <Col>
              <CandidateListItem
                item={item}
                itemNumber={index+1}
                namePrefix={`candidateListItem-${item._id}-`}
                //deleteItem={() => this.props.deleteItem(item._id)}
                //updateItem={value => this.props.updateItem(item._id, value)}
                opening={this.props.opening}
              />
            </Col>
          </Row>
        ))}
      </div>
    );
  }
}

export default withTranslation()(withListHandlers(CandidateList));
