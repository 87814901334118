import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CandidateListFilters from "./CandidateListFilters";
import CandidateListForJob from "./CandidateListForJob";
import {withTranslation} from "react-i18next";

class FeedTab extends React.Component {

  state = {
    feedFilters: {}
  };

  setFeedFilters = (filters) =>{
    this.setState({feedFilters: filters});
  };

  render() {
    const t = this.props.t;

    if (!this.props.opening) {
      return (
        <div className="CandidatesTab">
          {t("jobmanagement:candidate_list_no_opening")}
        </div>
      )
    }

    return (
      <div className="CandidatesTab spaced-children">
        {/*
        <Row>
          <Col>
            <CandidateListFilters filters={this.state.feedFilters} setFilters={this.setFeedFilters}/>
          </Col>
        </Row>
        */}
        <Row>
          <Col>
            <CandidateListForJob opening={this.props.opening} updateOpening={this.props.updateOpening}/>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(FeedTab);
