export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const SIGN_OUT = "/signout";
export const PROFILE = "/profile";
export const HOME = "/";
export const PASSWORD_CHANGE = "/pw-change";
export const PASSWORD_FORGET = "/pw-forget";
export const PRIVACY = "/privacy";
export const TERMS = "/terms";
export const CAREERS = "/careers";
export const CONTACT = "/contact";
export const JOB_MANAGEMENT = "/companies/company/:companyId/jobs";
export const JOB_OPENING_ADD = "/companies/company/:companyId/jobs/create";
export const JOB_PREVIEW = "/companies/company/:companyId/jobs/:jobId";
export const CANDIDATE_ALL = "/candidates";

export const ADMIN_FAIR = "/fairs";
export const ADMIN_FAIR_CREATE = "/fairs/fair/create";
export const ADMIN_FAIR_UPDATE = "/fairs/fair/:id/edit";
export const ADMIN_COMPANIES = "/fairs/employers";

export const MAW_MAIN_SITE = "https://mouseatwork.com";
