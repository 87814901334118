import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./DisconnectedModal.scss";
import {withTranslation} from "react-i18next";

class DisconnectedModal extends React.Component {

  render() {
    const t = this.props.t;

    return (
      <Modal show={this.props.show} onHide={this.props.handleClose} className="DisconnectedModal">
        <Modal.Body className={"spaced-children"}>
          <div>{t("session:disconnected_msg")}</div>
          <div className={"button-row"}>
            <Button variant="primary" onClick={this.props.handleClose}>
              {t("common:close_button")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withTranslation()(DisconnectedModal);
